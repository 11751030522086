import { mergeAssetConfig } from "@/config/asset";
import baseDeviceConfig from "@/config/base-device";

const DISPLAYED_PROPERTIES = ["temp_c", "rh_pct"];

const config = mergeAssetConfig(baseDeviceConfig, {
  i18nNamespace: "iaq",
  components: {
    AssetDashboardTab: {
      component: () => import("@/iaq/components/DeviceDashboardTab.vue"),
      props: {
        itemProperties: DISPLAYED_PROPERTIES,
        graphProperties: DISPLAYED_PROPERTIES
      }
    },
    AssetDialogProperties: {
      component: () => import("@/components/AssetDialogProperties.vue"),
      props: {
        itemProperties: DISPLAYED_PROPERTIES
      }
    }
  },
  categoryProperties: {
    temperature: { property: "temp_c" },
    humidity: { property: "rh_pct" }
  },
  properties: {
    temp_c: {
      unit: "degrees_c",
      unitSelectorFn: () => "degrees_f",
      altUnits: {
        degrees_f: {
          min: -30,
          max: 120,
          format: "integer",
          graphFormat: "decimal1dd",
          aggregation: "AVERAGE",
          fitBounds: true
        }
      },
      comparable: true
    },
    rh_pct: {
      min: 0,
      max: 100,
      format: "integer",
      unit: "pct",
      aggregation: "AVERAGE",
      comparable: true
    }
  }
});

export default config;
