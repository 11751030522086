import { getCurrentInstance, reactive, toRefs, watch } from "vue";

// Thanks: https://medium.com/better-programming/reactive-vue-routes-with-the-composition-api-18c1abd878d1
export function useRouter(): Record<any, any> {
  const vm = (getCurrentInstance() as any).proxy;

  const state = reactive({
    route: vm.$route
  });

  watch(
    () => vm.$route,
    r => {
      state.route = r;
    }
  );

  return { ...toRefs(state), router: vm.$router };
}
