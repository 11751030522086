
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import router from "@/router";
import gql from "graphql-tag";
import { findIndex, orderBy } from "lodash";
import FloorSelector from "@/components/FloorSelector.vue";
import FloorplanTopBar from "@/components/FloorplanTopBar.vue";
import FloorplanMap from "@/components/FloorplanMap.vue";
import AssetInspector from "@/components/AssetInspector.vue";
import { Floor, DecoratedAsset, GqlAsset } from "@/types";
import updateAssetMutation from "@/gql/update-asset-mutation";
import deleteAssetMutation from "@/gql/delete-asset-mutation";
import { decorateAsset, updateAssetProperties } from "@/config/asset";

@Component({
  components: {
    FloorSelector,
    FloorplanTopBar,
    FloorplanMap,
    AssetInspector
  },
  apollo: {
    unplacedAssets: {
      query: gql`
        query BuildingAssetsQuery($buildingUuid: ID!) {
          unplacedAssets: buildingAssets(buildingUuid: $buildingUuid) {
            assetUuid
            knownAssetUuid
            assetCategory {
              name
            }
            assetType {
              name
            }
            manufacturer {
              name
            }
            assetModel {
              name
            }
            smart
            online
            assetCategoryUuid
            assetTypeUuid
            manufacturerUuid
            assetModelUuid
            name
            serialNumber
            floorX
            floorY
            installationDate
            ... on Device {
              properties
              settings
              thresholds {
                values
              }
            }
          }
        }
      `,
      variables() {
        return { buildingUuid: router.currentRoute.params.buildingUuid };
      },
      update(data) {
        const assets: GqlAsset[] = data.unplacedAssets;
        return assets.map(asset => decorateAsset(asset));
      },
      fetchPolicy: "no-cache",
      notifyOnNetworkStatusChange: true
    },
    floorAssets: {
      query: gql`
        query FloorAssetsQuery($floorUuid: ID!) {
          floorAssets(floorUuid: $floorUuid) {
            assetUuid
            knownAssetUuid
            assetCategory {
              name
            }
            assetType {
              name
            }
            manufacturer {
              name
            }
            assetModel {
              name
            }
            smart
            online
            assetCategoryUuid
            assetTypeUuid
            manufacturerUuid
            assetModelUuid
            name
            serialNumber
            floorX
            floorY
            installationDate
            ... on Device {
              properties
              settings
              thresholds {
                values
              }
            }
          }
        }
      `,
      variables() {
        return { floorUuid: this.currentFloor.floorUuid };
      },
      skip() {
        return this.currentFloor === null || this.currentFloor.floorplan === null;
      },
      update(data) {
        const assets: GqlAsset[] = data.floorAssets;
        const { floorplan } = this.currentFloor;

        const newAssets: DecoratedAsset[] = assets.map(asset => ({
          ...decorateAsset(asset),
          floorRealX: (asset.floorX ?? 0) * floorplan.sizeX,
          floorRealY: (asset.floorY ?? 0) * floorplan.sizeY
        }));

        return [...this.floorAssets, ...newAssets];
      },
      watchLoading(isLoading) {
        if (isLoading) this.floorAssets = [];
      },
      fetchPolicy: "no-cache",
      notifyOnNetworkStatusChange: true
    },
    $subscribe: {
      buildingDataChanges: {
        query: gql`
          subscription AssetDataChanges($assetUuids: [ID!]!, $authorization: String!) {
            buildingDataChanges: assetDataChanges(assetUuids: $assetUuids, authorization: $authorization) {
              assetUuid
              property
              stamp
              value
            }
          }
        `,
        variables() {
          return {
            assetUuids: this.subscribableBuildingAssetIds,
            authorization: this.$store.getters.accessToken
          };
        },
        skip() {
          return this.subscribableBuildingAssetIds.length === 0;
        },
        result({ data }: Record<string, any>) {
          if (this.unplacedAssets && data.buildingDataChanges) {
            updateAssetProperties(this.unplacedAssets, data.buildingDataChanges);
          }
        }
      },
      floorDataChanges: {
        query: gql`
          subscription AssetDataChanges($assetUuids: [ID!]!, $authorization: String!) {
            floorDataChanges: assetDataChanges(assetUuids: $assetUuids, authorization: $authorization) {
              assetUuid
              property
              stamp
              value
            }
          }
        `,
        variables() {
          return {
            assetUuids: this.subscribableFloorAssetIds,
            authorization: this.$store.getters.accessToken
          };
        },
        skip() {
          return this.subscribableFloorAssetIds.length === 0;
        },
        result({ data }: Record<string, any>) {
          if (this.floorAssets.length > 0 && data.floorDataChanges) {
            updateAssetProperties(this.floorAssets, data.floorDataChanges);
          }
        }
      }
    }
  }
})
export default class BuildingFloorplansTab extends Vue {
  @Prop({ type: Object, required: true })
  readonly building: Record<string, any>;

  locked = true;
  addAssetDialog = false;
  floorSelectorToggled = false;
  unplacedAssets: DecoratedAsset[] | null = null;
  floorAssets: DecoratedAsset[] = [];
  inspectorTab = "status";

  async created(): Promise<void> {
    for (const floor of this.sortedFloors) {
      if (floor.floorplan) {
        this.$store.dispatch("preloadImage", floor.floorplan.url);
      }
    }
  }

  @Watch("$route", { immediate: true })
  goToTopFloor(): void {
    const floor = this.$route.query.floor as string;
    if (floor === undefined) {
      this.$router.replace({ query: { ...this.$route.query, floor: this.sortedFloors.length.toString() } });
    }
  }

  @Watch("currentFloor", { immediate: true })
  currentFloorChanged(): void {
    this.$store.commit("deselectAsset");
  }

  moveFloorAsset(asset: DecoratedAsset): void {
    if (this.currentFloor === null) return;

    const savedAsset = this.floorAssets.find(a => a.assetUuid === asset.assetUuid) || null;
    this.updateFloorAsset(asset);

    updateAssetMutation(asset).catch(() => {
      if (savedAsset) this.updateFloorAsset(savedAsset);
      this.$store.commit("showSnackbar", { color: "warning", key: "messages.save_failed" });
    });
  }

  removeAssetFromFloor(asset: DecoratedAsset): void {
    this.locked = false;

    const revertTransfer = this.transferAsset(asset, this.floorAssets, this.unplacedAssets);
    if (!revertTransfer) return;

    updateAssetMutation(asset).catch(() => {
      revertTransfer();
      this.$store.commit("showSnackbar", { color: "warning", key: "messages.save_failed" });
    });
  }

  assetDroppedOnFloor(asset: DecoratedAsset): void {
    this.locked = false;
    this.resetFilters();

    const revertTransfer = this.transferAsset(asset, this.unplacedAssets, this.floorAssets);
    if (!revertTransfer) return;

    updateAssetMutation(asset).catch(() => {
      revertTransfer();
      this.$store.commit("showSnackbar", { color: "warning", key: "messages.save_failed" });
    });
  }

  resetFilters(): void {
    const category = this.$route.query.category as string;
    const filter = this.$route.query.filter as string;
    if (category !== "name" || filter !== "all") {
      this.$router.replace({ query: { ...this.$route.query, category: "name", filter: "all" } });
    }
  }

  addAsset(asset: DecoratedAsset): void {
    if (this.unplacedAssets === null) return;
    this.unplacedAssets.push({ ...asset });
  }

  updateUnplacedAsset(asset: DecoratedAsset): void {
    if (this.unplacedAssets === null) return;

    const index = findIndex(this.unplacedAssets, a => a.assetUuid === asset.assetUuid);
    this.unplacedAssets.splice(index, 1, asset);
  }

  updateFloorAsset(asset: DecoratedAsset): void {
    const index = findIndex(this.floorAssets, a => a.assetUuid === asset.assetUuid);
    this.floorAssets.splice(index, 1, asset);
  }

  updateAsset(asset: DecoratedAsset): void {
    if (this.selectedAssetOnFloorplan) {
      this.updateFloorAsset(asset);
    } else {
      this.updateUnplacedAsset(asset);
    }
  }

  deleteUnplacedAsset(asset: DecoratedAsset): void {
    if (this.unplacedAssets === null) return;

    const savedAssets = [...this.unplacedAssets];
    this.unplacedAssets = this.unplacedAssets.filter(a => a.assetUuid !== asset.assetUuid);

    deleteAssetMutation(asset)
      .then(() => {
        this.$store.commit("showSnackbar", { color: "success", key: "messages.delete_succeeded" });
      })
      .catch(() => {
        this.unplacedAssets = savedAssets;
        this.$store.commit("showSnackbar", { color: "warning", key: "messages.delete_failed" });
      });
  }

  transferAsset(
    asset: DecoratedAsset,
    sourceArray: DecoratedAsset[] | null,
    destArray: DecoratedAsset[] | null
  ): undefined | (() => void) {
    if (sourceArray === null) return;

    const savedSource = [...sourceArray];
    const savedDest = destArray ? [...destArray] : null;

    const index = findIndex(sourceArray, a => a.assetUuid === asset.assetUuid);
    const savedAsset = sourceArray[index];

    sourceArray.splice(index, 1);
    if (destArray) destArray.push(asset);

    return () => {
      sourceArray.splice(0, sourceArray.length, ...savedSource);
      if (destArray && savedDest) destArray.splice(0, destArray.length, ...savedDest);
      sourceArray[index] = savedAsset;
    };
  }

  clickFloorplan(): void {
    if (this.desktop) {
      this.deselectAsset();
    } else {
      this.floorSelectorToggled = false;
    }
  }

  clickFloorplanContainer(): void {
    if (!this.currentFloor) this.clickFloorplan();
  }

  moveFloorplan(): void {
    this.floorSelectorToggled = false;
  }

  deselectAsset(): void {
    this.$store.commit("deselectAsset");
  }

  get showSidebarAsColumn(): boolean {
    return this.desktop;
  }

  get desktop(): boolean {
    return this.$vuetify.breakpoint.mdAndUp;
  }

  get currentFloor(): Floor | null {
    if (!this.sortedFloors || this.sortedFloors.length === 0) return null;
    const index = Number(this.$route.query.floor) ?? this.sortedFloors.length;
    return this.sortedFloors[this.sortedFloors.length - index] || null;
  }

  get sortedFloors(): Floor[] {
    return orderBy(this.building.floors, f => f.position, "desc");
  }

  get selectedAsset(): DecoratedAsset | null {
    const selectedId = this.$store.state.floorplan.selectedAssetUuid;
    if (!selectedId) return null;

    const unplacedAssets = this.unplacedAssets ?? [];
    const assets = [...this.floorAssets, ...unplacedAssets];
    return assets.find(a => a.assetUuid === selectedId) ?? null;
  }

  get selectedAssetOnFloorplan(): boolean {
    return this.$store.state.floorplan.selectedAssetOnFloorplan;
  }

  get subscribableBuildingAssetIds(): string[] {
    if (!this.unplacedAssets) return [];
    return this.unplacedAssets?.filter(a => a.smart).map(a => a.assetUuid);
  }

  get subscribableFloorAssetIds(): string[] {
    if (!this.floorAssets) return [];
    return this.floorAssets?.filter(a => a.smart).map(a => a.assetUuid);
  }

  get unplacedAssetsLoading(): boolean {
    return this.unplacedAssets === null || this.$apollo.queries.unplacedAssets.loading;
  }

  get unplacedAssetsColumnProps(): Record<string, any> {
    if (this.showSidebarAsColumn) {
      return {
        cols: 3,
        style: "min-width: 200px; max-width: 350px"
      };
    } else {
      return {
        style: "position: absolute; top: 0; left: 0; width: 75%; z-index: 10000"
      };
    }
  }

  get assetDetailOptions(): any[] {
    return [
      { text: this.$t("building.floorplans.show_asset_details.options.MODEL_NO"), value: "MODEL_NO" },
      { text: this.$t("building.floorplans.show_asset_details.options.NAME"), value: "NAME" }
    ];
  }
}
