
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { formatNumber } from "@/utils/number";
import { DecoratedAsset, DecoratedProperty, PropertyConfig } from "@/types";
import { TranslateResult } from "vue-i18n";
import { DateTime, Duration } from "luxon";

function elementBottomCenter(el: Element): [number, number] {
  const rect = el.getBoundingClientRect();
  return [rect.x + rect.width / 2, rect.y + rect.height];
}

const COLORS: Record<string, string> = {
  blue: "#1755F2",
  red: "#CC4648"
};

@Component
export default class DiagramProperty extends Vue {
  @Prop({ type: Object, required: true })
  readonly device: DecoratedAsset;

  @Prop({ type: Object, required: true })
  readonly property: DecoratedProperty;

  @Prop({ type: Number, required: true })
  readonly diameter: number;

  @Prop({ type: String, default: "blue" })
  readonly color: string;

  relativeTimestamp: Duration | null = null;
  interval: ReturnType<typeof setInterval> | null = null;

  onMouseEnter(event: MouseEvent): void {
    if (this.timestampDate === null || this.relativeTimestamp === null) return;

    const [x, y] = elementBottomCenter(event.target as Element);
    this.$emit("property-enter", {
      timestampDate: this.timestampDate,
      relativeTimestamp: this.relativeTimestamp,
      x,
      y
    });
  }

  onMouseLeave(): void {
    this.$emit("property-leave");
  }

  get label(): TranslateResult {
    const namespace = this.device.config.i18nNamespace;
    return this.$t(`properties.${namespace}.${this.property.name}.label`);
  }

  get textColor(): string {
    return COLORS[this.color] || this.color;
  }

  get valueSize(): number {
    const val = this.formattedValue ?? "";
    return val.length > 3 ? 24 : 30;
  }

  get xOffset(): number {
    const val = this.formattedValue ?? "";
    return val.length <= 1 ? 3 : 0;
  }

  get formattedValue(): string | null {
    return formatNumber(this.property.value, { format: this.propertyConfig.format });
  }

  get unitStr(): string {
    if (!this.propertyConfig.unit) return "";
    return this.$t(`units.${this.propertyConfig.unit}`).toString();
  }

  get propertyConfig(): PropertyConfig {
    return this.property.config;
  }

  get timestampDate(): DateTime | null {
    const { timestamp } = this.property;
    const zone = this.$store.getters.timeZone;
    return timestamp ? DateTime.fromISO(timestamp).setZone(zone) : null;
  }

  @Watch("timestampDate", { immediate: true })
  @Watch("device.config.staleDataDuration")
  timestampChanged(): void {
    this.updateRelativeTimestamp();

    if (this.interval) {
      clearInterval(this.interval);
      this.interval = null;
    }

    if (this.timestampDate) this.interval = setInterval(this.updateRelativeTimestamp, 10000);
  }

  updateRelativeTimestamp(): void {
    this.relativeTimestamp = this.timestampDate?.diffNow() ?? null;
  }
}
