
import { Vue, Component, Prop } from "vue-property-decorator";
import isNil from "lodash/isNil";
import { DateTime, Duration } from "luxon";
import { MetricIndicatorType } from "@/types";
import MetricItemTitle from "@/components/MetricItemTitle.vue";
import EditInPlace from "@/components/EditInPlace.vue";
import { indicatorColor, indicatorTextClass, indicatorIcon, indicatorIconClass } from "@/utils/models";

@Component({
  components: {
    MetricItemTitle,
    EditInPlace
  }
})
export default class MetricItem extends Vue {
  @Prop({ type: String, required: true })
  readonly label: string;

  @Prop({ required: true })
  readonly value: any;

  @Prop({ type: String })
  readonly unit: string | undefined;

  @Prop({ default: MetricIndicatorType.Plain })
  readonly indicatorType: MetricIndicatorType;

  @Prop({ type: Boolean, default: false })
  readonly hideIcon?: boolean;

  @Prop({ type: DateTime, default: null })
  readonly timestamp: DateTime | null;

  @Prop({ type: Duration, default: null })
  readonly relativeTimestamp: Duration | null;

  @Prop({ type: Boolean, default: false })
  readonly timestampAlwaysVisible: boolean;

  @Prop({ type: Boolean, default: false })
  readonly small?: boolean;

  @Prop({ type: Number, default: 0 })
  readonly subscriptionCount: number;

  @Prop({ type: Boolean, default: false })
  readonly loading: boolean;

  @Prop({ type: Boolean, default: false })
  readonly editInPlace: boolean;

  @Prop({ type: Boolean, default: false })
  readonly editing: boolean;

  @Prop({ type: Boolean, default: false })
  readonly saveDisabled: boolean;

  @Prop({ type: Boolean, default: false })
  readonly saving: boolean;

  show = false;
  hover = false;

  get color(): string {
    return indicatorColor(this.indicatorType);
  }

  get textColor(): string {
    return indicatorTextClass(this.indicatorType);
  }

  get icon(): string {
    return indicatorIcon(this.indicatorType);
  }

  get iconClass(): string {
    return indicatorIconClass(this.indicatorType);
  }

  get titleProps(): Record<string, any> {
    return {
      label: this.label,
      timestamp: this.timestamp,
      relativeTimestamp: this.relativeTimestamp,
      timestampAlwaysVisible: this.timestampAlwaysVisible,
      hover: this.hover,
      subscriptionCount: this.subscriptionCount,
      loading: this.loading
    };
  }

  get isValueMissing(): boolean {
    return isNil(this.value);
  }
}
