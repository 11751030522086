import { render, staticRenderFns } from "./SelectField.vue?vue&type=template&id=5383c8bd&"
import script from "./SelectField.vue?vue&type=script&setup=true&lang=ts&"
export * from "./SelectField.vue?vue&type=script&setup=true&lang=ts&"
import style0 from "./SelectField.vue?vue&type=style&index=0&id=5383c8bd&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VSelect } from 'vuetify/lib/components/VSelect';
installComponents(component, {VSelect})
