import { ref, Ref, unref } from "vue";
import { useQuery } from "@vue/apollo-composable";
import gql from "graphql-tag";
import { GqlBuilding, MaybeRef } from "@/types";

export interface UseBuildingOptions {
  enabled?: MaybeRef<boolean>;
}

export interface UseBuildingResult {
  building: Ref<GqlBuilding | undefined>;
  loadingError: Ref<boolean>;
  refetch: () => void;
  loading: Ref<boolean>;
}

export function useBuilding(
  buildingUuid: MaybeRef<string | null>,
  { enabled = true }: UseBuildingOptions = {}
): UseBuildingResult {
  const building = ref<GqlBuilding | undefined>();
  const loadingError = ref(false);

  const { onResult, onError, refetch, loading } = useQuery(
    gql`
      query Building($buildingUuid: ID!) {
        building(buildingUuid: $buildingUuid) {
          buildingUuid
          name
          timeZone
        }
      }
    `,
    {
      buildingUuid
    },
    () => ({
      enabled: unref(enabled) && unref(buildingUuid) !== null,
      fetchPolicy: "no-cache",
      notifyOnNetworkStatusChange: true
    })
  );

  onError(() => {
    loadingError.value = true;
  });

  onResult(queryResult => {
    if (queryResult.data) {
      building.value = queryResult.data.building;
    }
  });

  return { building, loadingError, refetch, loading };
}
