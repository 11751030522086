
import { Vue, Component } from "vue-property-decorator";
import gql from "graphql-tag";
import { DataTableHeader } from "vuetify";
import { DateTime } from "luxon";
import router from "@/router";
import NavbarLayout from "@/layouts/NavbarLayout.vue";
import SmartAssetName from "@/components/SmartAssetName.vue";
import RssiValue from "@/components/RssiValue.vue";
import { GqlAsset } from "@/types";
import { floorName } from "@/utils/models";
import { formatDuration } from "@/utils/date";
import { decorateAsset, getOptionalProperty } from "@/config/asset";

@Component({
  components: {
    NavbarLayout,
    SmartAssetName,
    RssiValue
  },

  apollo: {
    building: {
      query: gql`
        query Building($buildingUuid: ID!) {
          building(buildingUuid: $buildingUuid) {
            name
            assets(knownAssets: true) {
              assetUuid
              knownAssetUuid
              floor {
                position
                name
              }
              name
              smart
              online
              ... on Device {
                devEui
                miscFields
              }
            }
          }
        }
      `,
      variables() {
        return {
          buildingUuid: router.currentRoute.params.buildingUuid
        };
      },
      fetchPolicy: "no-cache",
      notifyOnNetworkStatusChange: true
    }
  }
})
export default class BuildingWirelessDeviceReport extends Vue {
  building: Record<string, any> | null = null;

  floorName = floorName;

  get buildingLoading(): boolean {
    return this.building === null || this.$apollo.queries.building.loading;
  }

  get items(): Record<string, any>[] {
    if (this.building === null) return [];
    return this.building.assets
      .map((rawAsset: GqlAsset) => {
        const asset = decorateAsset(rawAsset);
        return {
          ...asset,
          location: asset.floor?.position ?? 0,
          bestGatewayEui: getOptionalProperty(asset, "best_gateway_eui")?.value,
          rssi: getOptionalProperty(asset, "rssi")?.value,
          battery: getOptionalProperty(asset, "battery")?.value,
          rssiTimestamp: getOptionalProperty(asset, "rssi")?.timestamp
        };
      })
      .sort((a: Record<string, any>, b: Record<string, any>) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1));
  }

  get headers(): DataTableHeader[] {
    return [
      { text: this.$t("fields.asset.location.label").toString(), value: "location" },
      { text: this.$t("fields.asset.name.label").toString(), value: "name" },
      { text: this.$t("fields.asset.dev_eui.label").toString(), value: "devEui" },
      { text: this.$t("fields.asset.best_gateway_eui.label").toString(), value: "bestGatewayEui" },
      { text: this.$t("fields.asset.battery.label").toString(), value: "battery", align: "end" },
      { text: this.$t("fields.asset.rssi.label").toString(), value: "rssi", align: "end" },
      { text: this.$t("fields.asset.rssi_time_ago.label").toString(), value: "rssiTimestamp" }
    ];
  }

  formatEui(eui: string | null): string {
    if (!eui) return "--";

    const first = eui.slice(0, -4);
    const last = eui.slice(-4);
    return `${first}&nbsp;<strong>${last}</strong>`;
  }

  formatTimestamp(timestamp: string | undefined): string {
    if (!timestamp) return "--";

    const timestampDate = DateTime.fromISO(timestamp);
    const duration = timestampDate.diffNow();
    return formatDuration(duration, ["days", "hours", "minutes", "seconds"]);
  }

  get reportTitle(): string {
    const title = this.$t("building_wireless_device_report.title").toString();

    if (this.building === null) return title;
    return `${title} &mdash; ${this.building.name}`;
  }
}
