import gql from "graphql-tag";
import { apolloClient } from "@/plugins/vue-apollo";

export default function (annotationUuid: string, text: string): Promise<any> {
  return apolloClient
    .mutate({
      mutation: gql`
        mutation ($annotationUuid: ID!, $text: String!) {
          updateAnnotation(annotationUuid: $annotationUuid, text: $text) {
            message
            stamp
            statusCode
          }
        }
      `,
      variables: {
        annotationUuid: annotationUuid,
        text: text
      }
    })
    .then(response => {
      return response.data.updateAnnotation;
    });
}
