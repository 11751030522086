import gql from "graphql-tag";
import { apolloClient } from "@/plugins/vue-apollo";

export default function (documentUuid: string): Promise<any> {
  return apolloClient
    .query({
      query: gql`
        query ($documentUuid: ID!) {
          annotations(documentUuid: $documentUuid) {
            annotationUuid
            documentUuid
            userUuid
            stamp
            text
          }
        }
      `,
      variables: {
        documentUuid: documentUuid
      }
    })
    .then(response => {
      return response.data.annotations;
    });
}
