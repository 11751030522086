import { ComputedRef, computed } from "vue";
import {
  memoize,
  merge,
  fromPairs,
  toPairs,
  mapValues,
  assign,
  forOwn,
  find,
  isMatch,
  filter,
  ListIterateeCustom,
  isNil
} from "lodash";
import { DateTime } from "luxon";
import {
  GqlAsset,
  DecoratedAsset,
  PartialAssetConfig,
  AssetConfig,
  KnownAsset,
  KnownAssetPredicate,
  PropertyConfig,
  DecoratedProperty,
  ThresholdPair,
  Unit,
  GqlAssetDataChange,
  GqlProperty,
  ThresholdFields,
  FieldDescriptor,
  ParamProvider,
  FieldDescriptorObject,
  UnitOrDefault,
  ChildLink,
  ParentLink,
  GqlStateInfo
} from "@/types";
import { readFieldConfig } from "@/config/form";
import baseAssetConfig from "@/config/base-asset";
import { convertUnitWithFn, createConversionFns, round } from "@/utils/number";
import { convertPairsToThresholds } from "@/utils/models";
import {
  getIndexedField,
  setIndexedField,
  toDescriptorObject,
  descriptorMatchesDimensions
} from "@/utils/indexed-field";
import { DEFAULT_STALE_DATA_DURATION } from "./constants";

interface GetAssetConfigResult {
  knownAsset?: KnownAsset;
  config: AssetConfig;
}

interface GetPropertyOptions {
  buildIfMissing?: boolean;
  unit?: UnitOrDefault;
}

const DEFAULT_PROPERTY: PropertyConfig = {
  dataType: "number",
  category: "properties",
  dimensions: 0,
  dependsOnFields: [],
  labelKey: "label",
  hideValueUnit: false,
  comparable: false,
  format: "integer",
  aggregation: "MAXIMUM",
  stepGraph: false,
  fitBounds: false,
  fieldConfig: {}
};

const DEFAULT_ASSET: AssetConfig = {
  i18nNamespace: "asset",
  components: {},
  staleDataDuration: DEFAULT_STALE_DATA_DURATION,
  categoryProperties: {},
  properties: {},
  fields: {},
  pathMap: {}
};

export const DEFAULT_UNIT = "default_unit";

export function readPropertyConfig(config: Partial<PropertyConfig>): PropertyConfig {
  return {
    ...DEFAULT_PROPERTY,
    ...config
  };
}

export function readAssetConfig(config: PartialAssetConfig): AssetConfig {
  const properties = mapValues(config.properties, readPropertyConfig);
  const fields = mapValues(config.fields, readFieldConfig);
  const pathMap = {
    ...mapKeysToNames(properties),
    ...mapKeysToNames(fields)
  };

  return {
    ...DEFAULT_ASSET,
    ...config,
    properties,
    fields,
    pathMap
  };
}

export function mergeAssetConfig(base: PartialAssetConfig, config: PartialAssetConfig): AssetConfig {
  return merge({}, base, {
    ...DEFAULT_ASSET,
    ...readAssetConfig(merge({}, base, config))
  });
}

export const KNOWN_ASSETS: KnownAsset[] = [
  {
    name: "UBX-IAQ100",
    category: "IT",
    type: "Sensor - IAQ",
    subtype: "Indoor",
    knownAssetUuid: "105b7109-c0d0-4f84-8b97-49b9dd342ca8",
    config: require("@/iaq/config/ubx-iaq-100")
  },
  {
    name: "UBX-IAQ101",
    category: "IT",
    type: "Sensor - IAQ",
    subtype: "Indoor",
    knownAssetUuid: "7e3b1239-ad4f-465e-b893-5eefcc36c9c2",
    config: require("@/iaq/config/ubx-iaq-101")
  },
  {
    name: "UBX-IAQ102",
    category: "IT",
    type: "Sensor - IAQ",
    subtype: "Indoor",
    knownAssetUuid: "ba943727-bd97-4741-be65-0c9880ed3fba",
    config: require("@/iaq/config/ubx-iaq-102")
  },
  {
    name: "UBX-IAQ200",
    category: "IT",
    type: "Sensor - IAQ",
    subtype: "Indoor",
    knownAssetUuid: "5e29195f-50c7-4763-816b-2b54e679e998",
    config: require("@/iaq/config/ubx-iaq-200")
  },
  {
    name: "UBX-TEMP-RH100",
    category: "IT",
    type: "Sensor - IAQ",
    subtype: "Cold Storage",
    knownAssetUuid: "610dc07b-bdb2-4a3d-97f4-b4fba488a1bd",
    config: require("@/iaq/config/ubx-temp-rh-100")
  },
  {
    name: "UBX-NRG100",
    category: "IT",
    type: "Sensor - Energy",
    knownAssetUuid: "fa4338cc-3efc-400d-b704-e1b75a00ac44",
    config: require("@/energy-pro/config/ubx-nrg-100")
  },
  {
    name: "UBX-NRG200",
    category: "IT",
    type: "Sensor - Energy",
    knownAssetUuid: "3cfb8952-5f38-4dfa-95f7-d6dca255b392",
    config: require("@/energy-pro/config/ubx-nrg-200")
  },
  {
    name: "UBX-NRG201",
    category: "IT",
    type: "Sensor - Energy",
    knownAssetUuid: "af2ce77f-f29b-401d-bd89-c70a6f3bf3c4",
    config: require("@/energy-pro/config/ubx-nrg-201")
  },
  {
    name: "VS250CMe",
    category: "HVAC",
    type: "ERV",
    knownAssetUuid: "d105661b-2c48-42c1-9478-3fda89d7bc6d",
    config: require("@/vs/config/vs-250-cm")
  },
  {
    name: "VS250CMh",
    category: "HVAC",
    type: "HRV",
    knownAssetUuid: "1da06ab5-af2a-48a4-862f-9d8c7f65836b",
    config: require("@/vs/config/vs-250-cm")
  },
  {
    name: "VS400CMe",
    category: "HVAC",
    type: "ERV",
    knownAssetUuid: "b3d4541f-1486-4e56-8125-7dbe00e6ea64",
    config: require("@/vs/config/vs-400-cm")
  },
  {
    name: "VS400CMh",
    category: "HVAC",
    type: "HRV",
    knownAssetUuid: "e61cfbb9-abb9-4ad5-8499-dd661400e823",
    config: require("@/vs/config/vs-400-cm")
  },
  {
    name: "VS900CMe",
    category: "HVAC",
    type: "ERV",
    knownAssetUuid: "92f2fb13-0fca-4c4b-9407-7e42f6ea5f1c",
    config: require("@/vs/config/vs-900-cm")
  },
  {
    name: "VS900CMh",
    category: "HVAC",
    type: "HRV",
    knownAssetUuid: "da78c64b-793e-41c4-80f4-2419bb6aef2d",
    config: require("@/vs/config/vs-900-cm")
  },
  {
    name: "VS1200CMe",
    category: "HVAC",
    type: "ERV",
    knownAssetUuid: "f03dc154-31b3-43f3-85d8-177aa3cd9c8c",
    config: require("@/vs/config/vs-1200-cm")
  },
  {
    name: "VS1200CMh",
    category: "HVAC",
    type: "HRV",
    knownAssetUuid: "b4801aad-c6f6-43bf-a2c2-e2ae5f547218",
    config: require("@/vs/config/vs-1200-cm")
  },
  {
    name: "VS500SQe",
    category: "HVAC",
    type: "ERV",
    knownAssetUuid: "bf977338-f007-4580-9813-38bd0ffb3418",
    config: require("@/vs/config/vs-500-sq")
  },
  {
    name: "VS500SQh",
    category: "HVAC",
    type: "HRV",
    knownAssetUuid: "48433142-4d04-43c7-a188-1d376ec06be5",
    config: require("@/vs/config/vs-500-sq")
  },
  {
    name: "VS1000RTe",
    category: "HVAC",
    type: "ERV",
    knownAssetUuid: "3228bcfa-bf18-49fb-855f-5c68304972bc",
    config: require("@/vs/config/vs-1000-rt")
  },
  {
    name: "VS1000RTh",
    category: "HVAC",
    type: "HRV",
    knownAssetUuid: "392e4d4d-d3be-40b0-bd14-29c0ad23d184",
    config: require("@/vs/config/vs-1000-rt")
  },
  {
    name: "VS3000RTe",
    category: "HVAC",
    type: "ERV",
    knownAssetUuid: "973a0319-edf3-4576-aa88-9efb73536424",
    config: require("@/vs/config/vs-3000-rt")
  },
  {
    name: "VS3000RTh",
    category: "HVAC",
    type: "HRV",
    knownAssetUuid: "ff93b028-4d58-4bfb-a9e7-f3297b52ee61",
    config: require("@/vs/config/vs-3000-rt")
  },
  {
    name: "AOU48RLAVM",
    category: "HVAC",
    type: "VRF - ODU",
    knownAssetUuid: "a21c34f3-2355-4145-a35c-302f6d542adf",
    config: require("@/vrf/config/vrf-odu")
  },
  {
    name: "UTG-UFYC-W",
    category: "HVAC",
    type: "VRF - IDU",
    knownAssetUuid: "322cf051-1a6f-43e6-b887-a75babf1c25c",
    config: require("@/vrf/config/vrf-idu")
  },
  {
    name: "UBX-TSTAT100",
    category: "HVAC",
    type: "Thermostat",
    knownAssetUuid: "3c67bdfe-1a0a-44e8-8c5c-82885deb682a",
    config: require("@/tstat/config/ubx-tstat-100")
  },

  {
    name: "UBX-OCC100",
    category: "IT",
    type: "OCC",
    knownAssetUuid: "4f924526-45a4-4cf3-b2e2-7a482d2c014d",
    config: require("@/occupancy/config/ubx-occ-100")
  }
];

function matchKnownAsset(knownAsset: KnownAsset, predicate: KnownAssetPredicate): boolean {
  if (typeof predicate === "function") {
    return predicate(knownAsset);
  } else {
    return isMatch(knownAsset, predicate);
  }
}

export function findKnownAssets(predicate: KnownAssetPredicate): KnownAsset[] {
  return KNOWN_ASSETS.filter(ka => matchKnownAsset(ka, predicate));
}

export const getKnownAsset = memoize((predicate: KnownAssetPredicate): KnownAsset | undefined => {
  return find(KNOWN_ASSETS, ka => matchKnownAsset(ka, predicate));
});

export const getAssetConfig = memoize((knownAssetUuid?: string): GetAssetConfigResult => {
  if (!knownAssetUuid) return { config: baseAssetConfig };

  const knownAsset = getKnownAsset({ knownAssetUuid });
  if (knownAsset === undefined) {
    // eslint-disable-next-line no-console
    console.error(`Unable to find asset config for known asset UUID: ${knownAssetUuid}`);
    return { config: baseAssetConfig };
  }
  return { knownAsset, config: knownAsset.config.default };
});

export function destinationUnit(propertyConfig: PropertyConfig): Unit | undefined {
  return propertyConfig.unitSelectorFn?.();
}

export function getPropertyConfig(
  assetConfig: AssetConfig,
  descriptor: FieldDescriptor,
  unit: UnitOrDefault | null = null
): PropertyConfig {
  const { name } = toDescriptorObject(descriptor);
  let config: PropertyConfig | undefined = assetConfig.properties?.[name];
  if (!config) throw Error(`Property config not found for: ${name}`);

  const srcUnit = config.unit;
  const destUnit = unit === DEFAULT_UNIT ? config.unitSelectorFn?.() : unit;

  if (srcUnit && destUnit && destUnit !== srcUnit) {
    const altConfig = config.altUnits?.[destUnit] ?? {};

    config = {
      ...config,
      ...altConfig,
      unit: destUnit,
      ...createConversionFns(srcUnit, destUnit)
    };
  }

  return config;
}

export const formConfig = baseAssetConfig;

function buildThresholdFields(propertyConfig: PropertyConfig, thresholdArray: ThresholdPair[]): ThresholdFields {
  const convert = propertyConfig.convertValueFn;
  if (convert) {
    thresholdArray = thresholdArray.map(threshold => {
      return { ...threshold, compareValue: round(convert(threshold.compareValue)) ?? 0 };
    });
  }

  return {
    thresholdArray,
    thresholds: convertPairsToThresholds(thresholdArray)
  };
}

export function buildProperty(
  asset: DecoratedAsset,
  descriptor: FieldDescriptorObject,
  attributes: Partial<GqlProperty> | null
): DecoratedProperty {
  const propertyConfig = getPropertyConfig(asset.config, descriptor.name);
  const thresholdArray = asset.thresholds[descriptor.name] ?? [];

  return {
    ...descriptor,
    value: attributes?.value ?? null,
    timestamp: attributes?.stamp ?? null,
    update_stamp: attributes?.update_stamp ?? null,
    state: attributes?.state ?? null,
    pending: attributes?.pending ?? false,
    state_info: attributes?.state_info ?? {},
    ...buildThresholdFields(propertyConfig, thresholdArray),
    config: propertyConfig
  };
}

function createProperty(
  asset: DecoratedAsset,
  descriptor: FieldDescriptorObject,
  attributes: Partial<GqlProperty> | null
): DecoratedProperty {
  const property = buildProperty(asset, descriptor, attributes);
  setIndexedField(asset.properties, descriptor, property);
  return property;
}

function createProperties(asset: DecoratedAsset, gqlProperties: Record<string, GqlProperty<any> | null>): void {
  forOwn(gqlProperties, (gqlProperty, key) => {
    const descriptor = parseKey(asset.config, key);
    if (!descriptor) return;

    createProperty(asset, descriptor, gqlProperty);
  });
}

export function convertProperty(
  asset: DecoratedAsset,
  property: DecoratedProperty,
  unit: UnitOrDefault | null = DEFAULT_UNIT
): DecoratedProperty {
  if (!unit) return property;

  const config = getPropertyConfig(asset.config, property.name, unit);
  const { convertValueFn } = config;
  const params = assetParamProvider(asset);
  let { value, thresholdArray } = property;

  if (convertValueFn) {
    value = convertUnitWithFn(value, convertValueFn, params);
    thresholdArray = thresholdArray.map(threshold => ({
      ...threshold,
      compareValue: round(convertUnitWithFn(threshold.compareValue, convertValueFn, params)) ?? 0
    }));
  }

  return {
    ...property,
    value,
    thresholdArray,
    thresholds: convertPairsToThresholds(thresholdArray),
    config
  };
}

export function assetParamProvider(asset: DecoratedAsset): ParamProvider {
  const cache: Record<string, ComputedRef<any>> = {};
  return key => {
    cache[key] ||= computed(() => getOptionalProperty(asset, key)?.value);
    return cache[key].value;
  };
}

export function getProperty(
  asset: DecoratedAsset,
  descriptor: FieldDescriptor,
  options: GetPropertyOptions = {}
): DecoratedProperty {
  const descriptorObj = toDescriptorObject(descriptor);
  const property = getOptionalProperty(asset, descriptorObj, { ...options, buildIfMissing: true });
  if (property) return property;

  return buildProperty(asset, descriptorObj, {});
}

export function getOptionalProperty(
  asset: DecoratedAsset,
  descriptor: FieldDescriptor,
  { buildIfMissing = false, unit = undefined }: GetPropertyOptions = {}
): DecoratedProperty | undefined {
  const descriptorObj = toDescriptorObject(descriptor);
  const { dimensions } = getPropertyConfig(asset.config, descriptorObj.name);

  if (!descriptorMatchesDimensions(descriptorObj, dimensions)) {
    const { name, params } = descriptorObj;
    throw Error(`Incorrect params for property ${name} (${dimensions} needed): [${params}]`);
  }

  let property = getIndexedField(asset.properties, descriptorObj);

  if (!property && buildIfMissing) {
    property = buildProperty(asset, descriptorObj, {});
  }

  if (property && unit) {
    property = convertProperty(asset, property, unit);
  }

  return property;
}

function upsertProperty(
  asset: DecoratedAsset,
  descriptor: FieldDescriptorObject,
  attributes: Partial<GqlProperty>
): DecoratedProperty {
  const property = getOptionalProperty(asset, descriptor);
  if (property) {
    const newProperty = buildProperty(asset, descriptor, attributes);
    // Copy into existing property to preserve reactivity
    assign(property, newProperty);
    return property;
  } else {
    const newProperty = createProperty(asset, descriptor, attributes);
    return newProperty;
  }
}

export function decorateAsset(asset: GqlAsset): DecoratedAsset {
  const { knownAsset, config } = getAssetConfig(asset.knownAssetUuid);

  const convertedFields = {
    installationDate: asset.installationDate ? DateTime.fromISO(asset.installationDate) : null
  };

  const locked = !!asset.deviceLock?.expiration;

  const decoratedAsset: DecoratedAsset = {
    ...asset,
    ...convertedFields,
    properties: {},
    thresholds: asset.thresholds?.values ?? {},
    config,
    knownAsset,
    locked
  };

  createProperties(decoratedAsset, asset.properties ?? {});
  createProperties(decoratedAsset, asset.settings ?? {});
  createProperties(decoratedAsset, asset.miscFields ?? {});

  return decoratedAsset;
}

function updateDecoratedAssetProperty(asset: DecoratedAsset, change: GqlAssetDataChange): void {
  const descriptor = parseKey(asset.config, change.property);
  if (!descriptor) return;

  if (!isNil(change.deviceLock)) {
    asset.locked = change.deviceLock;
  }

  const stateInfo: GqlStateInfo = { new_value: change.stateChange?.after.value };
  const attributes: Partial<GqlProperty> = {
    value: change.value,
    stamp: change.stamp,
    update_stamp: change.stateChange?.after.stamp,
    state: change.stateChange?.after.state,
    pending: change.stateChange?.after.pending ?? false,
    state_info: stateInfo
  };

  upsertProperty(asset, descriptor, attributes);
}

export function updateAssetProperties(assets: DecoratedAsset[], changes: GqlAssetDataChange[]): void {
  changes.forEach(change => {
    const asset = assets.find(a => a.assetUuid === change.assetUuid);
    if (asset) {
      updateDecoratedAssetProperty(asset, change);
    }
  });
}

export function updateThresholds(asset: DecoratedAsset, propertyName: string, thresholdArray: ThresholdPair[]): void {
  const propertyObj = getProperty(asset, propertyName);
  const thresholdFields = buildThresholdFields(propertyObj.config, thresholdArray);
  assign(propertyObj, thresholdFields);
}

function mapKeysToNames(fields: Record<string, { key?: string }>): Record<string, string> {
  const pairs = toPairs(fields).map(([name, fieldConfig]) => {
    const key = fieldConfig.key ?? name;
    return [key, name];
  });
  return fromPairs(pairs);
}

function parseKey(assetConfig: AssetConfig, key: string): FieldDescriptorObject | undefined {
  const keyParts = key.match(/^(.*?)(\[.*)?$/);
  if (!keyParts) return undefined;

  const namePart = keyParts[1];
  const paramsPart: string | undefined = keyParts[2];

  const name = assetConfig.pathMap[namePart];
  if (!name) return undefined;
  let params: number[];

  if (paramsPart) {
    const numbers = paramsPart.match(/\d+/g);
    params = numbers ? numbers.map(m => parseInt(m[0])) : [];
  } else {
    params = [];
  }

  const fieldConfig = assetConfig.properties[name];
  if (!fieldConfig || fieldConfig.dimensions !== params.length) {
    return undefined;
  }

  return {
    name,
    params
  };
}

export function propertyKey(property: DecoratedProperty): string {
  const { name, params, config } = property;
  const key = config.key ?? name;
  const indicesStr = params.map(p => `[${p}]`).join("");
  return `${key}${indicesStr}`;
}

export function getParentAssets(
  asset: GqlAsset | DecoratedAsset,
  predicate?: ListIterateeCustom<ParentLink, boolean> | undefined
): DecoratedAsset[] {
  const parentLinks = asset.parentLinks ?? [];
  const matchingLinks = filter(parentLinks, predicate);
  return matchingLinks.map(l => decorateAsset(l.parentAsset));
}

export function getFirstParentAsset(
  asset: GqlAsset | DecoratedAsset,
  predicate?: ListIterateeCustom<ParentLink, boolean> | undefined
): DecoratedAsset | null {
  const assets = getParentAssets(asset, predicate);
  return assets[0] ?? null;
}

export function getChildAssets(
  asset: GqlAsset | DecoratedAsset,
  predicate?: ListIterateeCustom<ChildLink, boolean> | undefined
): DecoratedAsset[] {
  const childLinks = asset.childLinks ?? [];
  const matchingLinks = filter(childLinks, predicate);
  return matchingLinks.map(l => decorateAsset(l.childAsset));
}
