
import { Vue, Component, Prop } from "vue-property-decorator";
import gql from "graphql-tag";
import { isNil } from "lodash";
import { DateTime } from "luxon";
import ValidatedForm from "@/components/ValidatedForm.vue";
import FormField from "@/components/FormField.vue";
import { SelectOption, Asset, ComboboxValue, DecoratedAsset, GqlAsset } from "@/types";
import { decorateAsset, formConfig } from "@/config/asset";
import { isBlank } from "@/utils/string";
import createAssetMutation from "@/gql/create-asset-mutation";
import updateAssetMutation from "@/gql/update-asset-mutation";
import createManufacturerMutation from "@/gql/create-manufacturer-mutation";
import createModelMutation from "@/gql/create-model-mutation";

export interface AssetFields {
  name: string;
  serialNumber: string;
  assetCategoryUuid: string | null;
  assetTypeUuid: string | null;
  manufacturer: ComboboxValue;
  model: ComboboxValue;
  installationDate: DateTime | null;
  knownAssetUuid: string | null;
}

const EMPTY_ASSET: AssetFields = {
  name: "",
  assetCategoryUuid: null,
  assetTypeUuid: null,
  manufacturer: { id: null, text: null },
  model: { id: null, text: null },
  serialNumber: "",
  installationDate: null,
  knownAssetUuid: null
};

@Component({
  components: {
    ValidatedForm,
    FormField
  },
  apollo: {
    categories: {
      query: gql`
        query AssetCategoriesQuery {
          categories: assetCategories {
            assetCategoryUuid
            name
            assetTypes {
              assetTypeUuid
              name
            }
          }
        }
      `,
      skip() {
        return !this.loadData;
      }
    },
    manufacturers: {
      query: gql`
        query ManufacturersQuery($accountUuid: ID!) {
          manufacturers: assetAccountManufacturers(accountUuid: $accountUuid) {
            manufacturerUuid
            name
          }
        }
      `,
      variables() {
        return {
          accountUuid: this.$store.getters.accountUuid
        };
      },
      skip() {
        return !this.loadData;
      },
      // Don't cache because newly-created options need to be present next time
      // the dialog is opened.
      fetchPolicy: "no-cache",
      notifyOnNetworkStatusChange: true
    },
    models: {
      query: gql`
        query ModelsQuery($accountUuid: ID!, $manufacturerUuid: ID!) {
          models: assetAccountModels(accountUuid: $accountUuid, manufacturerUuid: $manufacturerUuid) {
            assetModelUuid
            name
          }
        }
      `,
      variables() {
        return {
          accountUuid: this.$store.getters.accountUuid,
          manufacturerUuid: this.form.manufacturer.id
        };
      },
      skip() {
        return this.form.manufacturer.id === null;
      },
      // Don't cache because newly-created options need to be present next time
      // the dialog is opened.
      fetchPolicy: "no-cache",
      notifyOnNetworkStatusChange: true
    }
  }
})
export default class AssetForm extends Vue {
  @Prop({ type: Object })
  readonly asset: DecoratedAsset;

  @Prop({ type: Boolean, default: true })
  readonly loadData: boolean;

  form: AssetFields = { ...EMPTY_ASSET };
  categories: Record<string, any>[] | null = null;
  manufacturers: Record<string, any>[] | null = null;
  models: Record<string, any>[] | null = null;
  submitting = false;

  formConfig = formConfig;

  created(): void {
    this.resetForm();
  }

  resetForm(): void {
    if (this.isCreating) {
      this.form = { ...EMPTY_ASSET };
    } else {
      this.form = {
        name: this.asset.name,
        assetCategoryUuid: this.asset.assetCategoryUuid,
        assetTypeUuid: this.asset.assetTypeUuid,
        manufacturer: { id: this.asset.manufacturerUuid, text: null },
        model: { id: this.asset.assetModelUuid, text: null },
        serialNumber: this.asset.serialNumber,
        installationDate: this.asset.installationDate,
        knownAssetUuid: this.asset.knownAssetUuid
      };
    }
  }

  async submit(): Promise<void> {
    if (this.submitting) return;
    this.submitting = true;

    // Allow combobox fields to blur before saving, so new values can propagate.
    setTimeout(() => {
      this.handleSubmit();
    });
  }

  async handleSubmit(): Promise<void> {
    const { manufacturer, model } = this.form;

    if (!isBlank(manufacturer.text) && manufacturer.id === null) {
      manufacturer.id = await this.createManufacturer(manufacturer.text as string);
    }

    if (manufacturer.id !== null && !isBlank(model.text) && model.id === null) {
      model.id = await this.createModel(manufacturer.id, model.text as string);
    }

    if (this.isCreating) {
      this.createAsset();
    } else {
      this.updateAsset();
    }
  }

  async createManufacturer(name: string): Promise<string | null> {
    let manufacturerUuid = null;

    await createManufacturerMutation(name).then(payload => {
      manufacturerUuid = payload.manufacturerUuid;
    });

    return manufacturerUuid;
  }

  async createModel(manufacturerUuid: string, name: string): Promise<string | null> {
    let assetModelUuid = null;

    await createModelMutation(manufacturerUuid, name).then(payload => {
      assetModelUuid = payload.assetModelUuid;
    });

    return assetModelUuid;
  }

  async createAsset(): Promise<void> {
    const asset: Asset = {
      buildingUuid: this.$route.params.buildingUuid,
      ...this.asset,
      ...this.assetFields
    };

    createAssetMutation(asset)
      .then(response => {
        const createdAsset: GqlAsset = {
          ...asset,
          ...this.propertyNames,
          ...response
        };

        this.resetForm();
        this.$store.commit("showSnackbar", { color: "success", key: "messages.save_succeeded" });
        this.$emit("success", decorateAsset(createdAsset));
      })
      .catch(() => {
        this.$store.commit("showSnackbar", { color: "warning", key: "messages.save_failed" });
      })
      .finally(() => {
        this.submitting = false;
      });
  }

  async updateAsset(): Promise<void> {
    const asset: Asset = {
      ...this.asset,
      ...this.assetFields
    };

    updateAssetMutation(asset)
      .then(response => {
        const updatedAsset: GqlAsset = {
          ...asset,
          ...this.propertyNames,
          ...response
        };

        this.resetForm();
        this.$store.commit("showSnackbar", { color: "success", key: "messages.save_succeeded" });
        this.$emit("success", decorateAsset(updatedAsset));
      })
      .catch(() => {
        this.$store.commit("showSnackbar", { color: "warning", key: "messages.save_failed" });
      })
      .finally(() => {
        this.submitting = false;
      });
  }

  cancel(): void {
    this.resetForm();
    this.$emit("cancel");
  }

  get assetFields(): Record<string, any> {
    return {
      name: this.form.name,
      assetCategoryUuid: this.form.assetCategoryUuid,
      assetTypeUuid: this.form.assetTypeUuid,
      manufacturerUuid: this.form.manufacturer.id,
      assetModelUuid: this.form.model.id,
      serialNumber: this.form.serialNumber,
      installationDate: this.form.installationDate
    };
  }

  get propertyNames(): Record<string, any> {
    return {
      assetCategory: {
        name: this.selectedCategory?.name
      },
      assetType: {
        name: this.selectedType?.name
      },
      manufacturer: {
        name: this.selectedManufacturerName
      },
      assetModel: {
        name: this.selectedModelName
      }
    };
  }

  clearType(): void {
    this.form.assetTypeUuid = null;
  }

  clearModel(): void {
    this.form.model = { id: null, text: null };

    if (this.form.manufacturer.id === null) {
      this.models = [];
    }
  }

  get categoryOptions(): SelectOption[] {
    if (this.categories === null) return [];

    return this.categories.map(category => {
      return { text: category.name, value: category.assetCategoryUuid };
    });
  }

  get typeOptions(): SelectOption[] {
    if (this.selectedCategory === null) return [];

    return this.selectedCategory.assetTypes.map((type: any) => {
      return { text: type.name, value: type.assetTypeUuid };
    });
  }

  get manufacturerOptions(): SelectOption[] {
    if (this.manufacturers === null) return [];

    return this.manufacturers.map(manufacturer => {
      return { text: manufacturer.name, value: manufacturer.manufacturerUuid };
    });
  }

  get modelOptions(): SelectOption[] {
    if (this.models === null) return [];

    return this.models.map(model => {
      return { text: model.name, value: model.assetModelUuid };
    });
  }

  get selectedCategory(): Record<string, any> | null {
    if (this.categories === null) return null;
    return this.categories.find(c => c.assetCategoryUuid === this.form.assetCategoryUuid) || null;
  }

  get selectedType(): Record<string, any> | null {
    if (this.selectedCategory === null) return null;
    return this.selectedCategory.assetTypes.find((t: any) => t.assetTypeUuid === this.form.assetTypeUuid) || null;
  }

  get selectedManufacturerName(): string | null {
    if (!isNil(this.form.manufacturer.text)) return this.form.manufacturer.text;
    if (this.manufacturers === null) return null;

    const manufacturer = this.manufacturers.find((m: any) => m.manufacturerUuid === this.form.manufacturer.id);
    return manufacturer?.name || null;
  }

  get selectedModelName(): string | null {
    if (!isNil(this.form.model.text)) return this.form.model.text;
    if (this.models === null) return null;

    const model = this.models.find((m: any) => m.assetModelUuid === this.form.model.id);
    return model?.name || null;
  }

  get isCreating(): boolean {
    return !this.asset;
  }
}
