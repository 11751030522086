
import { Vue, Component, Prop } from "vue-property-decorator";
import router from "@/router";
import gql from "graphql-tag";
import { DataTableHeader } from "vuetify";
import { Asset } from "@/types";
import { floorName } from "@/utils/models";
import SmartAssetName from "@/components/SmartAssetName.vue";

@Component({
  components: {
    SmartAssetName
  },
  apollo: {
    assets: {
      query: gql`
        query BuildingAssetsQuery($buildingUuid: ID!) {
          assets: buildingAssets(buildingUuid: $buildingUuid, includeFloorAssets: true) {
            assetUuid
            assetCategory {
              name
            }
            assetType {
              name
            }
            manufacturer {
              name
            }
            assetModel {
              name
            }
            floor {
              position
              name
            }
            name
            smart
            online
            serialNumber
            ... on Device {
              devEui
            }
          }
        }
      `,
      variables() {
        return { buildingUuid: router.currentRoute.params.buildingUuid };
      },
      error(): void {
        this.loadingError = true;
      },
      fetchPolicy: "no-cache",
      notifyOnNetworkStatusChange: true
    }
  }
})
export default class BuildingAssetsTab extends Vue {
  @Prop({ type: Object, required: true })
  readonly building: Record<string, any>;

  assets: Asset[];
  loadingError = false;

  floorName = floorName;

  get headers(): DataTableHeader[] {
    return [
      { text: this.$t("fields.asset.name.label").toString(), value: "name" },
      { text: this.$t("fields.asset.category.label").toString(), value: "assetCategory.name" },
      { text: this.$t("fields.asset.type.label").toString(), value: "assetType.name" },
      { text: this.$t("fields.asset.manufacturer.label").toString(), value: "manufacturer.name" },
      { text: this.$t("fields.asset.model.label").toString(), value: "assetModel.name" },
      { text: this.$t("fields.asset.serialNumber.short_label").toString(), value: "serialNumber" },
      { text: this.$t("fields.asset.dev_eui.label").toString(), value: "devEui" },
      { text: this.$t("fields.asset.location.label").toString(), value: "location" }
    ];
  }
}
