const state = {
  breadcrumbs: []
};

const getters = {
  // eslint-disable-next-line
  breadcrumbs(state: Record<any, any>): Record<string, any>[] {
    return state.breadcrumbs;
  }
};

const mutations = {
  setBreadcrumbs(state: Record<any, any>, breadcrumbs: Record<string, any>[]): void {
    state.breadcrumbs = breadcrumbs;
  }
};

export default {
  state,
  getters,
  mutations
};
