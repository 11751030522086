import { intersection, isEmpty, isEqual, isNil } from "lodash";
import { mergeAssetConfig } from "@/config/asset";
import baseDeviceConfig from "@/config/base-device";
import { getValue, getFieldObject } from "@/config/form";
import { FieldDescriptorParams, Form, FormConfig, PropFnArgs } from "@/types";
import { installRule } from "@/plugins/vee-validate/utils";
import { compareTimes } from "@/utils/date";
import { isBlank } from "@/utils/string";

export const PLANS = ["wake", "away", "home", "sleep"];
export const CONTROL_MODES = ["HEAT", "EMERGENCY HEAT", "COOL", "AUTO"];
export const FAN_MODES = ["AUTO", "ON", "CIRCULATE"];
export const EMPTY_TIME = "0:00";
export const MAX_BANKS = 16;
export const SCHEDULE_TIME_FIELDS = ["schedule_enabled", "schedule_time", "schedule_days"];

type BankDays = string[] | undefined;
type BankTimeDays = [string | undefined, BankDays];

installRule("tstat_unique_time", {
  validate(value, params) {
    const { days: thisBankDays, otherBanks } = params as { days: BankDays; otherBanks: BankTimeDays[] };
    if (isBlank(value) || isEmpty(thisBankDays) || isEmpty(otherBanks)) return true;

    return otherBanks.every(([time, days]) => {
      if (isEmpty(days)) return true;
      const timesDiffer = compareTimes(value, time ?? "") !== 0;
      const daysDiffer = intersection(thisBankDays, days).length === 0;
      return timesDiffer || daysDiffer;
    });
  },
  params: ["days", "otherBanks"]
});

function bankDays({ model, params }: PropFnArgs): string[] {
  const descriptor = { name: "schedule_days", params };
  return getValue(model.config, model, descriptor) ?? [];
}

function otherBanks({ model, params }: PropFnArgs): BankTimeDays[] {
  const banks: BankTimeDays[] = [];

  for (const plan of PLANS) {
    const planIndex = planToIndex(plan);
    const banksCount = banksLength(model.config, model, planIndex);

    for (let bank = 0; bank < banksCount; bank++) {
      const bankParams = [planIndex, bank];
      if (isEqual(params, bankParams)) continue;

      const time = getValue(model.config, model, { name: "schedule_time", params: bankParams }) as string | null;
      const days = getValue(model.config, model, { name: "schedule_days", params: bankParams }) as string[] | null;
      banks.push([time ?? undefined, days ?? []]);
    }
  }

  return banks;
}

export function banksLength(
  config: FormConfig,
  form: Form,
  planIndex: number,
  notEmptyIfNew = true,
  notEmptyIfDeleted = false
): number {
  for (let i = MAX_BANKS; i >= 1; i--) {
    if (!isBankEmpty(config, form, [planIndex, i - 1], notEmptyIfNew, notEmptyIfDeleted)) return i;
  }

  return 0;
}

export function isBankEmpty(
  config: FormConfig,
  form: Form,
  params: FieldDescriptorParams,
  notEmptyIfNew = true,
  notEmptyIfDeleted = false
): boolean {
  const enabledField = getFieldObject(config, form, { name: "schedule_enabled", params });
  const timeField = getFieldObject(config, form, { name: "schedule_time", params });
  const daysField = getFieldObject(config, form, { name: "schedule_days", params });

  if (notEmptyIfNew && (enabledField.new || timeField.new || daysField.new)) return false;
  if (notEmptyIfDeleted && (enabledField.deleted || timeField.deleted || daysField.deleted)) return false;

  const enabled = enabledField.value as boolean | null;
  const time = timeField.value as string | null;
  const days = daysField.value as string[] | null;

  return (isNil(enabled) || enabled === false) && (isEmpty(time) || time === EMPTY_TIME) && isEmpty(days);
}

function shouldValidateBank(config: FormConfig, form: Form, params: FieldDescriptorParams): boolean {
  return !isBankEmpty(config, form, params, false);
}

export function planToIndex(plan: string): number {
  return PLANS.indexOf(plan);
}

export function controlModeToIndex(mode: string): number {
  const index = CONTROL_MODES.indexOf(mode);
  return index === -1 ? 0 : index;
}

export function targetTemperatureParams(model: Form): FieldDescriptorParams {
  const mode = getValue(model.config, model, "temperature_control_mode");
  return [controlModeToIndex(mode)];
}

const config = mergeAssetConfig(baseDeviceConfig, {
  i18nNamespace: "tstat",
  image: require("@/tstat/images/tstat.svg"),
  components: {
    AssetDashboardTab: () => import("@/tstat/components/DeviceDashboardTab.vue"),
    AssetConfigTab: () => import("@/tstat/components/DeviceConfigTab.vue"),
    AssetControlPanel: () => import("@/tstat/components/TstatControlPanel.vue"),
    AssetDialogProperties: {
      component: () => import("@/components/AssetDialogProperties.vue"),
      props: {
        itemProperties: ["ambient_temperature"]
      }
    }
  },
  categoryProperties: {
    temperature: { property: "ambient_temperature" }
  },
  properties: {
    ambient_temperature: {
      unit: "degrees_c",
      unitSelectorFn: () => "degrees_f",
      altUnits: {
        degrees_f: {
          graphFormat: "decimal1dd",
          aggregation: "AVERAGE",
          fitBounds: true
        }
      },
      comparable: true
    },
    system_status: {
      dataType: "boolean",
      category: "settings",
      labelKey: "switch_label"
    },
    temperature_control_mode: {
      dataType: "string",
      category: "settings",
      key: "temperature_control_mode__mode",
      fieldConfig: {
        displayType: "select",
        options: CONTROL_MODES,
        rules: { required: true }
      }
    },
    fan_mode: {
      dataType: "string",
      category: "settings",
      fieldConfig: {
        displayType: "select",
        options: FAN_MODES,
        rules: { required: true }
      }
    },
    historical_target_temperature: {
      dataType: "number",
      category: "settings",
      key: "target_temperature",
      format: "integer",
      unit: "degrees_c",
      unitSelectorFn: () => "degrees_f",
      altUnits: {
        degrees_f: {
          graphFormat: "decimal1dd",
          aggregation: "AVERAGE",
          fitBounds: true
        }
      },
      comparable: true
    },
    target_temperature: {
      dataType: "number",
      dimensions: 1,
      category: "settings",
      key: "temperature_control_mode__point",
      dependsOnFields: ["temperature_control_mode"],
      format: "integer",
      unit: "degrees_c",
      unitSelectorFn: () => "degrees_f",
      paramsSelectorFn: ({ model }) => targetTemperatureParams(model),
      fieldConfig: {
        rules: {
          required: true,
          between: { min: 32, max: 91 },
          integer: true
        }
      }
    },
    temperature_tolerance: {
      dataType: "number",
      category: "settings",
      key: "temperature_tolerance__point",
      format: "integer",
      unit: "degrees_c_relative",
      unitSelectorFn: () => "degrees_f_relative",
      fieldConfig: {
        rules: {
          required: true,
          between: { min: 0, max: 100 },
          integer: true
        }
      }
    },
    temperature_control_tolerance: {
      dataType: "number",
      category: "settings",
      key: "temperature_tolerance__control",
      dependsOnFields: ["temperature_control_mode"],
      format: "integer",
      unit: "degrees_c_relative",
      unitSelectorFn: () => "degrees_f_relative",
      fieldConfig: {
        disabled: ({ model }) => getValue(model.config, model, "temperature_control_mode") !== "AUTO",
        rules: {
          required: true,
          between: { min: 0, max: 100 },
          integer: true
        }
      }
    },
    schedule_fan_mode: {
      dataType: "string",
      dimensions: 1,
      category: "settings",
      key: "schedule_content__fan_mode",
      fieldConfig: {
        displayType: "select",
        options: FAN_MODES,
        rules: { required: true }
      }
    },
    schedule_target_temp: {
      dataType: "number",
      dimensions: 1,
      category: "settings",
      key: "schedule_content__target_temp",
      format: "integer",
      unit: "degrees_c",
      unitSelectorFn: () => "degrees_f",
      fieldConfig: {
        rules: {
          required: true,
          between: { min: 32, max: 91 },
          integer: true
        }
      }
    },
    schedule_tolerance_temp: {
      dataType: "number",
      dimensions: 1,
      category: "settings",
      key: "schedule_content__tolerance_temp",
      format: "integer",
      unit: "degrees_c_relative",
      unitSelectorFn: () => "degrees_f_relative",
      fieldConfig: {
        rules: {
          required: true,
          between: { min: 0, max: 100 },
          integer: true
        }
      }
    },
    schedule_enabled: {
      dataType: "boolean",
      dimensions: 2,
      category: "settings",
      key: "schedule_time__enabled",
      labelKey: "switch_label"
    },
    schedule_time: {
      dataType: "time",
      dimensions: 2,
      category: "settings",
      key: "schedule_time__time",
      fieldConfig: {
        rules: propArgs => ({
          required: shouldValidateBank(propArgs.model.config, propArgs.model, propArgs.params),
          tstat_unique_time: {
            days: bankDays(propArgs),
            otherBanks: otherBanks(propArgs)
          }
        })
      }
    },
    schedule_days: {
      dataType: "string",
      dimensions: 2,
      category: "settings",
      key: "schedule_time__days",
      fieldConfig: {
        displayType: "select",
        options: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
        multiple: true
      }
    },
    time_zone: {
      dataType: "number",
      category: "settings",
      fieldConfig: {
        displayType: "select",
        rules: { required: true }
      }
    },
    dst_enabled: {
      dataType: "boolean",
      category: "settings",
      key: "dst__enabled",
      labelKey: "switch_label"
    },
    dst_start_month: {
      dataType: "string",
      category: "settings",
      key: "dst__start_month",
      fieldConfig: {
        displayType: "select",
        disabled: ({ model }) => !getValue(model.config, model, "dst_enabled"),
        rules: { required: true }
      }
    },
    dst_start_week: {
      dataType: "number",
      category: "settings",
      key: "dst__start_week",
      fieldConfig: {
        disabled: ({ model }) => !getValue(model.config, model, "dst_enabled"),
        rules: {
          required: true,
          between: { min: 1, max: 5 },
          integer: true
        }
      }
    },
    dst_start_day: {
      dataType: "string",
      category: "settings",
      key: "dst__start_day",
      fieldConfig: {
        displayType: "select",
        disabled: ({ model }) => !getValue(model.config, model, "dst_enabled"),
        rules: { required: true }
      }
    },
    dst_start_hour: {
      dataType: "number",
      category: "settings",
      key: "dst__start_hour",
      fieldConfig: {
        disabled: ({ model }) => !getValue(model.config, model, "dst_enabled"),
        rules: {
          required: true,
          between: { min: 0, max: 23 },
          integer: true
        }
      }
    },
    dst_end_month: {
      dataType: "string",
      category: "settings",
      key: "dst__end_month",
      fieldConfig: {
        displayType: "select",
        disabled: ({ model }) => !getValue(model.config, model, "dst_enabled"),
        rules: { required: true }
      }
    },
    dst_end_week: {
      dataType: "number",
      category: "settings",
      key: "dst__end_week",
      fieldConfig: {
        disabled: ({ model }) => !getValue(model.config, model, "dst_enabled"),
        rules: {
          required: true,
          between: { min: 1, max: 5 },
          integer: true
        }
      }
    },
    dst_end_day: {
      dataType: "string",
      category: "settings",
      key: "dst__end_day",
      fieldConfig: {
        displayType: "select",
        disabled: ({ model }) => !getValue(model.config, model, "dst_enabled"),
        rules: { required: true }
      }
    },
    dst_end_hour: {
      dataType: "number",
      category: "settings",
      key: "dst__end_hour",
      fieldConfig: {
        disabled: ({ model }) => !getValue(model.config, model, "dst_enabled"),
        rules: {
          required: true,
          between: { min: 0, max: 23 },
          integer: true
        }
      }
    },
    child_lock_system: {
      dataType: "boolean",
      category: "settings",
      key: "child_lock__system",
      labelKey: "switch_label"
    },
    child_lock_temp_up: {
      dataType: "boolean",
      category: "settings",
      key: "child_lock__temp_up",
      labelKey: "switch_label"
    },
    child_lock_temp_down: {
      dataType: "boolean",
      category: "settings",
      key: "child_lock__temp_down",
      labelKey: "switch_label"
    },
    child_lock_fan_mode: {
      dataType: "boolean",
      category: "settings",
      key: "child_lock__fan_mode",
      labelKey: "switch_label"
    },
    child_lock_temp_control_mode: {
      dataType: "boolean",
      category: "settings",
      key: "child_lock__temp_control_mode",
      labelKey: "switch_label"
    },
    child_lock_reset: {
      dataType: "boolean",
      category: "settings",
      key: "child_lock__reset",
      labelKey: "switch_label"
    }
  }
});

export default config;
