
import { Vue, Component } from "vue-property-decorator";
import {
  mdiViewDashboard,
  mdiWidgets,
  mdiAlarmPanel,
  mdiFileDocument,
  mdiChartAreasplineVariant,
  mdiBell,
  mdiHistory,
  mdiCog
} from "@mdi/js";
import router from "@/router";
import gql from "graphql-tag";
import AppLayout from "@/layouts/AppLayout.vue";
import BuildingHeader from "@/components/BuildingHeader.vue";
import features from "@/config/features";

@Component({
  components: {
    AppLayout,
    BuildingHeader
  },
  apollo: {
    building: {
      query: gql`
        query BuildingQuery($buildingUuid: ID!) {
          building(buildingUuid: $buildingUuid) {
            buildingUuid
            name
            fullAddress
            online
            gpsCoordinates {
              lat
              lng
            }
            timeZone
            floors {
              floorUuid
              position
              name
              floorplan {
                url
                sizeX
                sizeY
              }
            }
          }
        }
      `,
      variables() {
        return { buildingUuid: router.currentRoute.params.buildingUuid };
      },
      fetchPolicy: "no-cache",
      notifyOnNetworkStatusChange: true,
      result({ data }: Record<string, any>) {
        if (!data?.building) return;

        if (this.initialLoading) {
          this.initialLoading = false;
          this.$store.commit("finishLoading");
        }

        this.$store.commit("setContextualTimeZone", data.building.timeZone ?? null);
        this.building = data.building;
        this.setBreadcrumbs();
      },
      error(): void {
        if (this.building === null) {
          this.$store.commit("finishLoadingWithError");
        }
      }
    }
  }
})
export default class BuildingView extends Vue {
  building: Record<string, any> | null = null;
  initialLoading = true;

  icons = {
    mdiViewDashboard,
    mdiWidgets,
    mdiAlarmPanel,
    mdiFileDocument,
    mdiChartAreasplineVariant,
    mdiBell,
    mdiHistory,
    mdiCog
  };
  features = features;

  created(): void {
    this.$store.commit("startLoading");
  }

  setBreadcrumbs(): void {
    if (!this.building) return;

    const breadcrumbs = [
      {
        text: this.building.name,
        to: { name: "building", params: { buildingUuid: router.currentRoute.params.buildingUuid } }
      }
    ];

    this.$store.commit("setBreadcrumbs", breadcrumbs);
  }

  // eslint-disable-next-line
  beforeRouteLeave(to: any, from: any, next: () => void): void {
    this.$store.commit("setContextualTimeZone", null);
    next();
  }
}
