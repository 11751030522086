
import { Vue, Component, Prop } from "vue-property-decorator";
import DeviceDiagram from "../DeviceDiagram.vue";
import DiagramProperty from "../DiagramProperty.vue";
import { DecoratedAsset } from "@/types";
import { DEFAULT_UNIT, getProperty } from "@/config/asset";

@Component({
  components: {
    DeviceDiagram,
    DiagramProperty
  }
})
export default class Vs400Cm extends Vue {
  @Prop({ type: Object, required: true })
  readonly asset: DecoratedAsset;

  property(name: string): Record<string, any> {
    return {
      device: this.asset,
      property: getProperty(this.asset, name, { unit: DEFAULT_UNIT })
    };
  }
}
