import gql from "graphql-tag";
import { apolloClient } from "@/plugins/vue-apollo";
import { ContactFields } from "@/types";

export default function (contactUuid: string, contact: ContactFields, isUserContact = false): Promise<any> {
  if (isUserContact) {
    return apolloClient
      .mutate({
        mutation: gql`
          mutation ($contact: ContactUpdate!) {
            updateUserContactInfo(contact: $contact)
          }
        `,
        variables: {
          contact: contact
        }
      })
      .then(response => {
        return response.data.updateUserContactInfo;
      });
  } else {
    return apolloClient
      .mutate({
        mutation: gql`
          mutation ($contactUuid: ID!, $contact: ContactUpdate!) {
            updateContact(contactInfoUuid: $contactUuid, contact: $contact)
          }
        `,
        variables: {
          contactUuid: contactUuid,
          contact: contact
        }
      })
      .then(response => {
        return response.data.updateContact;
      });
  }
}
