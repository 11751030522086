var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(_setup.Drop,{staticStyle:{"position":"relative"},on:{"drop":_setup.assetDropped,"dragenter":function($event){return _setup.emit('hide-floor-selector')}}},[(_vm.loading || _setup.floorplanLoading)?_c('v-progress-linear',{staticStyle:{"position":"absolute","top":"0"},attrs:{"indeterminate":""}}):_vm._e(),_c(_setup.LMap,{key:_setup.key,ref:"mapContainer",staticStyle:{"outline":"none","background-color":"inherit"},attrs:{"min-zoom":_setup.minZoom,"crs":_setup.CRS.Simple,"max-bounds":_setup.floorBounds,"options":{
      zoomSnap: 0,
      zoomDelta: 0.5,
      wheelPxPerZoomLevel: 15,
      zoomControl: false,
      attributionControl: false
    }},on:{"click":function($event){return _setup.emit('click-floorplan')},"movestart":_setup.handlePanZoom,"zoomstart":_setup.handlePanZoom}},[(!_setup.floorplanLoading && _setup.floorplan)?_c(_setup.LImageOverlay,{attrs:{"url":_setup.floorplan.url,"bounds":_setup.floorBounds},on:{"ready":_setup.mapReady}}):_vm._e(),_vm._l((_vm.assets),function(asset){return _c(_setup.AssetMarker,{key:asset.assetUuid,attrs:{"asset":asset,"locked":_vm.locked},on:{"move-asset":_setup.moveAsset}})}),_c(_setup.LControlZoom,{attrs:{"position":"bottomleft"}})],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }