
import { Vue, Component } from "vue-property-decorator";
import { PropertyEnterEvent } from "@/types";
import { formatDuration } from "@/utils/date";

@Component
export default class DeviceDiagram extends Vue {
  hoveredProperty: PropertyEnterEvent | null = null;

  get slotEventHandlers(): Record<string, any> {
    return {
      "property-enter": this.showPropertyTooltip,
      "property-leave": this.hidePropertyTooltip
    };
  }

  showPropertyTooltip(event: PropertyEnterEvent): void {
    this.hoveredProperty = event;
  }

  hidePropertyTooltip(): void {
    this.hoveredProperty = null;
  }

  get formattedRelativeTimestamp(): string {
    if (!this.hoveredProperty) return "";
    return formatDuration(this.hoveredProperty.relativeTimestamp, ["days", "hours", "minutes"]);
  }

  get formattedTimestamp(): string {
    if (!this.hoveredProperty) return "";
    return this.hoveredProperty.timestampDate.toFormat("yyyy-MM-dd - hh:mm a");
  }
}
