
import { Vue, Component } from "vue-property-decorator";
import gql from "graphql-tag";
import AssetComponent from "@/components/AssetComponent.vue";
import { decorateAsset, updateAssetProperties } from "@/config/asset";
import { DecoratedAsset } from "@/types";
import { deviceLock } from "@/gql/fragments/deviceLock";

@Component({
  components: {
    AssetComponent
  },
  apollo: {
    asset: {
      query: gql`
        ${deviceLock}
        query AssetQuery($assetUuid: ID!) {
          asset(assetUuid: $assetUuid) {
            assetUuid
            knownAssetUuid
            name
            serialNumber
            building {
              buildingUuid
              name
              timeZone
              online
            }
            assetCategory {
              name
            }
            assetType {
              name
            }
            manufacturer {
              name
            }
            assetModel {
              name
            }
            smart
            online
            assetCategoryUuid
            assetTypeUuid
            manufacturerUuid
            assetModelUuid
            installationDate
            childLinks {
              linkType
              childAsset {
                assetUuid
                knownAssetUuid
                name
              }
            }
            ... on Device {
              properties
              settings
              miscFields
              thresholds {
                values
              }
              ...DeviceLockFields
            }
          }
        }
      `,
      variables() {
        return {
          assetUuid: this.$route.params.assetUuid
        };
      },
      fetchPolicy: "no-cache",
      notifyOnNetworkStatusChange: true,
      result({ data }: Record<string, any>) {
        if (!data || !data.asset) return;

        if (this.initialLoading) {
          this.initialLoading = false;
          this.$store.commit("finishLoading");
        }

        this.$store.commit("setContextualTimeZone", data.asset.building.timeZone ?? null);
        this.asset = decorateAsset(data.asset);
        this.setBreadcrumbs();
      },
      error(): void {
        if (this.building === null) {
          this.$store.commit("finishLoadingWithError");
        }
      }
    },
    $subscribe: {
      assetDataChanges: {
        query: gql`
          subscription AssetDataChanges($assetUuids: [ID!]!, $authorization: String!) {
            assetDataChanges(assetUuids: $assetUuids, authorization: $authorization) {
              assetUuid
              property
              stamp
              value
            }
          }
        `,
        variables() {
          return {
            assetUuids: [this.$route.params.assetUuid],
            authorization: this.$store.getters.accessToken
          };
        },
        skip() {
          return !this.asset?.knownAssetUuid || !this.$store.getters.accessToken;
        },
        result({ data }: Record<string, any>) {
          if (this.asset && data.assetDataChanges) {
            updateAssetProperties([this.asset], data.assetDataChanges);
          }
        }
      }
    }
  }
})
export default class AssetView extends Vue {
  asset: DecoratedAsset | null = null;
  initialLoading = true;

  created(): void {
    this.$store.commit("startLoading");
  }

  refreshAsset(): void {
    this.$apollo.queries.asset.refresh();
  }

  setBreadcrumbs(): void {
    if (!this.asset || !this.asset.building) return;

    const breadcrumbs = [
      {
        text: this.asset.building.name,
        to: { name: "building", params: { buildingUuid: this.asset.building.buildingUuid } }
      },
      {
        text: this.asset.name,
        to: { name: "asset", params: { assetUuid: this.$route.params.assetUuid } }
      }
    ];

    this.$store.commit("setBreadcrumbs", breadcrumbs);
  }

  // eslint-disable-next-line
  beforeRouteLeave(to: any, from: any, next: () => void): void {
    this.$store.commit("setContextualTimeZone", null);
    next();
  }
}
