import { mapValues } from "lodash";

// By default, features are only enabled in the local environment.
// Enable them here using the Capistrano stage names.
const featureFlags: Record<string, Record<string, boolean>> = {
  assetHistory: {
    test: true,
    development: true,
    staging: true
  },
  alerting: {
    test: true,
    development: true,
    staging: true,
    production: true
  },
  buildingControls: {
    test: true,
    development: true,
    staging: true
  },
  enableVsFlowOffset: {
    test: true,
    development: true,
    staging: true
  },
  vsScheduling: {
    test: true,
    development: true,
    staging: true,
    production: true
  },
  stateManagement: {}
};

const environment = process.env.VUE_APP_ENVIRONMENT ?? "local";
const features = mapValues(featureFlags, environments => {
  return environment === "local" ? true : environments[environment] ?? false;
});

export default features;
