import { OptionsProp, DisabledProp, RangeFn } from "@/types";
import { mergeAssetConfig } from "@/config/asset";
import baseDeviceConfig from "@/config/base-device";
import { getValue } from "@/config/form";
import features from "@/config/features";

const TARGET_TEMP_LIMITS: Record<string, { min: number; max: number }> = {
  SUPPLY: { min: 50, max: 113 },
  EXTRACT: { min: 50, max: 86 },
  ROOM: { min: 50, max: 86 }
};

const IAQ_LIMITS: Record<string, { min: number; max: number }> = {
  CO2_2000: { min: 350, max: 2000 },
  CO2_1100: { min: 350, max: 1100 },
  RH: { min: 0, max: 100 },
  VOC: { min: 0, max: 100 },
  EXT: { min: 0, max: 100 }
};

const tempSensorOptions: OptionsProp = ({ model }) => {
  const options = ["SUPPLY", "EXTRACT"];
  if (getValue(model.config, model, "room_sensor_present")) options.push("ROOM");
  return options;
};

const targetTempLimits: RangeFn = ({ model }) => {
  const location = getValue(model.config, model, "temp_sensor_location") || "SUPPLY";
  return TARGET_TEMP_LIMITS[location];
};

const iaqDcvLimitsDisabled: DisabledProp = ({ model }) => {
  return !(
    getValue(model.config, model, "iaq_sensor") !== "None" && getValue(model.config, model, "operating_mode") === "DCV"
  );
};

const iaqLimits: RangeFn = ({ model }) => {
  const sensor = getValue(model.config, model, "iaq_sensor") || "None";
  return IAQ_LIMITS[sensor];
};

const previousBankEndTime: RangeFn = ({ model, params }) => {
  const [day, bank] = params;
  // If this is the first bank, there's nothing to compare against
  if (bank <= 1) return null;

  const descriptor = { name: "schedule_end", params: [day, bank - 1] };
  return getValue(model.config, model, descriptor);
};

const bankStartTime: RangeFn = ({ model, params }) => {
  const descriptor = { name: "schedule_start", params };
  return getValue(model.config, model, descriptor) ?? "00:00";
};

const config = mergeAssetConfig(baseDeviceConfig, {
  i18nNamespace: "ventilator",
  image: require("@/vs/images/generic-ventilator.svg"),
  components: {
    AssetDashboardTab: () => import("@/vs/components/DeviceDashboardTab.vue"),
    AssetConfigTab: () => import("@/vs/components/DeviceConfigTab.vue"),
    AssetControlPanel: () => import("@/vs/components/ControlPanel.vue"),
    AssetHistoryTab: {
      component: () => import("@/vs/components/DeviceHistoryTab.vue")
    },
    AssetDialogProperties: {
      component: () => import("@/components/AssetDialogProperties.vue"),
      props: {
        itemProperties: ["outside_temp", "supply_temp", "bypass_percent", "return_temp", "airflow_in", "airflow_out"],
        disableStalenessIndicator: true
      }
    }
  },
  // Temporarily prevent HRV fields from being displayed as stale under normal
  // operational circumstances (Jonah's request)
  staleDataDuration: 99999,
  categoryProperties: {
    temperature: { property: "supply_temp" }
  },
  properties: {
    co2: {
      format: "integer",
      unit: "ppm"
    },
    rh: {
      format: "integer",
      unit: "pct"
    },
    voc: {
      format: "integer",
      unit: "ppm"
    },
    external_sensor_percent: {
      format: "integer",
      unit: "pct"
    },
    supply_filter_used_percent: {
      min: 0,
      max: 100,
      format: "integer",
      unit: "pct"
    },
    extract_filter_used_percent: {
      min: 0,
      max: 100,
      format: "integer",
      unit: "pct"
    },
    supply_temp: {
      format: "integer",
      unit: "degrees_c",
      unitSelectorFn: () => "degrees_f",
      comparable: true
    },
    return_temp: {
      format: "integer",
      unit: "degrees_c",
      unitSelectorFn: () => "degrees_f",
      comparable: true
    },
    outside_temp: {
      format: "integer",
      unit: "degrees_c",
      unitSelectorFn: () => "degrees_f",
      comparable: true
    },
    bypass_percent: {
      min: 0,
      max: 100,
      format: "integer",
      unit: "pct"
    },
    exhaust_temp: {
      format: "integer",
      unit: "degrees_c",
      unitSelectorFn: () => "degrees_f",
      comparable: true
    },
    airflow_out: {
      format: "integer",
      unit: "cfm",
      comparable: true
    },
    airflow_in: {
      format: "integer",
      unit: "cfm",
      comparable: true
    },
    preheater_percent: {
      min: 0,
      max: 100,
      format: "integer",
      unit: "pct"
    },
    postheater_percent: {
      min: 0,
      max: 100,
      format: "integer",
      unit: "pct"
    },
    powered: {
      dataType: "boolean",
      category: "settings"
    },
    motherboard_firmware: {
      dataType: "string",
      category: "settings",
      fieldConfig: {
        readonly: true
      }
    },
    control_panel_firmware: {
      dataType: "string",
      category: "settings",
      fieldConfig: {
        readonly: true
      }
    },
    operating_mode: {
      dataType: "string",
      category: "settings",
      fieldConfig: {
        displayType: "select",
        options: ["CAV", "VAV", "DCV"],
        rules: { required: true }
      }
    },
    room_sensor_present: {
      dataType: "boolean",
      category: "settings"
    },
    temp_sensor_location: {
      dataType: "string",
      category: "settings",
      fieldConfig: {
        displayType: "select",
        options: tempSensorOptions,
        rules: { required: true }
      }
    },
    target_temp: {
      dataType: "number",
      category: "settings",
      dependsOnFields: ["temp_sensor_location"],
      format: "integer",
      unit: "degrees_c",
      unitSelectorFn: () => "degrees_f",
      fieldConfig: {
        rules: propArgs => ({
          required: true,
          between: targetTempLimits(propArgs),
          integer: true
        })
      }
    },
    target_airflow: {
      dataType: "number",
      category: "settings",
      dependsOnFields: ["operating_mode"],
      format: "integer",
      unit: "cfm",
      fieldConfig: {
        disabled: ({ model }) => getValue(model.config, model, "operating_mode") !== "CAV",
        rules: {
          required: true,
          between: {}, // Limits are overridden for each model
          integer: true
        }
      }
    },
    flow_offset: {
      dataType: "number",
      category: "settings",
      format: "integer",
      unit: "pct",
      fieldConfig: {
        readonly: features.enableVsFlowOffset !== true,
        rules: { required: true, between: { min: -100, max: 50 }, integer: true }
      }
    },
    temp_flow_reduction_enabled: {
      dataType: "boolean",
      category: "settings"
    },
    temp_flow_reduction_active: {
      dataType: "boolean",
      category: "settings"
    },
    ext_is_occupancy: {
      dataType: "boolean",
      category: "settings"
    },
    fire_occupancy_sensor_value: {
      dataType: "boolean",
      category: "settings"
    },
    boost_active: {
      dataType: "boolean",
      category: "settings"
    },
    boost_timer: {
      dataType: "number",
      category: "settings",
      format: "integer",
      unit: "mins",
      fieldConfig: {
        rules: { required: true, between: { min: 1, max: 60 }, integer: true }
      }
    },
    boost_flow: {
      dataType: "number",
      category: "settings",
      format: "integer",
      unit: "pct",
      fieldConfig: {
        rules: { required: true, between: { min: 50, max: 100 }, integer: true }
      }
    },
    unoccupied_timer: {
      dataType: "number",
      category: "settings",
      format: "integer",
      unit: "mins",
      fieldConfig: {
        rules: { required: true, between: { min: 1, max: 60 }, integer: true }
      }
    },
    unoccupied_flow: {
      dataType: "number",
      category: "settings",
      format: "integer",
      unit: "pct",
      fieldConfig: {
        rules: { required: true, between: { min: 20, max: 50 }, integer: true }
      }
    },
    preheater_type: {
      dataType: "string",
      category: "settings",
      fieldConfig: {
        enum: true,
        readonly: true
      }
    },
    economizer_enabled: {
      dataType: "boolean",
      category: "settings",
      labelKey: "switch_label",
      fieldConfig: {
        rules: { required: true }
      }
    },
    economizer_active: {
      dataType: "boolean",
      category: "settings"
    },
    economizer_airflow: {
      dataType: "number",
      category: "settings",
      format: "integer",
      unit: "pct",
      fieldConfig: {
        disabled: ({ model }) => !getValue(model.config, model, "economizer_enabled"),
        rules: { required: true, between: { min: 50, max: 100 }, integer: true }
      }
    },
    economizer_temperature: {
      dataType: "number",
      category: "settings",
      format: "integer",
      unit: "degrees_c",
      unitSelectorFn: () => "degrees_f",
      fieldConfig: {
        disabled: ({ model }) => !getValue(model.config, model, "economizer_enabled"),
        rules: {
          required: true,
          between: { min: 53, max: 77 },
          integer: true
        }
      }
    },
    economizer_start: {
      dataType: "string",
      category: "settings",
      fieldConfig: {
        disabled: ({ model }) => !getValue(model.config, model, "economizer_enabled"),
        mapsToFields: {
          mappingType: "datetime_to_month_day_time",
          fieldNames: ["economizer_start_date", "economizer_start_time"]
        },
        mappedfields: {
          economizer_start_date: {
            rules: { required: true }
          },
          economizer_start_time: {
            rules: { required: true }
          }
        }
      }
    },
    economizer_end: {
      dataType: "string",
      category: "settings",
      fieldConfig: {
        disabled: ({ model }) => !getValue(model.config, model, "economizer_enabled"),
        mapsToFields: {
          mappingType: "datetime_to_month_day_time",
          fieldNames: ["economizer_end_date", "economizer_end_time"]
        },
        mappedfields: {
          economizer_end_date: {
            rules: { required: true }
          },
          economizer_end_time: {
            rules: { required: true }
          }
        }
      }
    },
    iaq_sensor: {
      dataType: "string",
      category: "settings",
      fieldConfig: {
        displayType: "select",
        options: ["None", "CO2_2000", "CO2_1100", "RH", "VOC", "EXT"],
        rules: ({ model }) => ({
          excluded: getValue(model.config, model, "operating_mode") === "DCV" ? ["None"] : []
        })
      }
    },
    iaq_dcv_min: {
      dataType: "number",
      category: "settings",
      format: "integer",
      fieldConfig: {
        disabled: iaqDcvLimitsDisabled,
        rules: propArgs => ({
          required: true,
          integer: true,
          between: iaqLimits(propArgs)
        })
      }
    },
    iaq_dcv_max: {
      dataType: "number",
      category: "settings",
      format: "integer",
      fieldConfig: {
        disabled: iaqDcvLimitsDisabled,
        rules: propArgs => ({
          required: true,
          integer: true,
          between: iaqLimits(propArgs),
          greater_rel: "@ventilator.iaq_dcv_min"
        })
      }
    },
    schedule_enabled: {
      dataType: "boolean",
      category: "settings",
      labelKey: "switch_label"
    },
    schedule_active: {
      dataType: "boolean",
      category: "settings"
    },
    wireless_dcv_enable: {
      dataType: "boolean",
      category: "settings",
      labelKey: "switch_label",
      fieldConfig: {
        disabled: ({ model }) => !getValue(model.config, model, "schedule_enabled")
      }
    },
    linked_co2_sensors: {
      category: "settings",
      fieldConfig: {
        disabled: ({ model }) =>
          !getValue(model.config, model, "schedule_enabled") || !getValue(model.config, model, "wireless_dcv_enable")
      }
    },
    zones_min_flow: {
      dataType: "number",
      category: "settings",
      format: "integer",
      unit: "cfm",
      fieldConfig: {
        disabled: ({ model }) =>
          !getValue(model.config, model, "schedule_enabled") || !getValue(model.config, model, "wireless_dcv_enable"),
        rules: {
          required: true,
          between: {}, // Limits are overridden for each model
          integer: true
        }
      }
    },
    zones_max_flow: {
      dataType: "number",
      category: "settings",
      format: "integer",
      unit: "cfm",
      fieldConfig: {
        disabled: ({ model }) =>
          !getValue(model.config, model, "schedule_enabled") || !getValue(model.config, model, "wireless_dcv_enable"),
        rules: {
          required: true,
          between: {}, // Limits are overridden for each model
          integer: true
        }
      }
    },
    max_co2_above_ambient: {
      dataType: "number",
      category: "settings",
      format: "integer",
      unit: "ppm",
      fieldConfig: {
        disabled: ({ model }) =>
          !getValue(model.config, model, "schedule_enabled") || !getValue(model.config, model, "wireless_dcv_enable"),
        rules: {
          required: true,
          between: { min: 0, max: 2100 },
          integer: true
        }
      }
    },
    schedule_start: {
      dataType: "time",
      dimensions: 2,
      category: "settings",
      key: "hrv_schedule__start",
      fieldConfig: {
        disabled: ({ model }) => !getValue(model.config, model, "schedule_enabled"),
        rules: propArgs => ({
          required: true,
          greater_than_equal_time: previousBankEndTime(propArgs)
        })
      }
    },
    schedule_end: {
      dataType: "time",
      dimensions: 2,
      category: "settings",
      key: "hrv_schedule__end",
      fieldConfig: {
        disabled: ({ model }) => !getValue(model.config, model, "schedule_enabled"),
        rules: propArgs => ({
          required: true,
          greater_than_time: bankStartTime(propArgs)
        })
      }
    },
    schedule_airflow: {
      dataType: "number",
      dimensions: 2,
      category: "settings",
      key: "hrv_schedule__flow",
      format: "integer",
      unit: "cfm",
      fieldConfig: {
        disabled: ({ model }) => !getValue(model.config, model, "schedule_enabled"),
        rules: {
          required: true,
          between: {}, // Limits are overridden for each model
          integer: true
        }
      }
    },
    schedule_temp: {
      dataType: "number",
      dimensions: 2,
      category: "settings",
      key: "hrv_schedule__temp",
      format: "integer",
      unit: "degrees_c",
      unitSelectorFn: () => "degrees_f",
      fieldConfig: {
        disabled: ({ model }) => !getValue(model.config, model, "schedule_enabled"),
        rules: propArgs => ({
          required: true,
          between: targetTempLimits(propArgs),
          integer: true
        })
      }
    },
    rtc: {
      dataType: "string",
      category: "settings",
      fieldConfig: {
        disabled: ({ model }) => !getValue(model.config, model, "edit_rtc_enabled"),
        mapsToFields: {
          mappingType: "datetime_to_date_time",
          fieldNames: ["rtc_date", "rtc_time"]
        },
        mappedfields: {
          rtc_date: {
            rules: { required: true }
          },
          rtc_time: {
            rules: { required: true }
          }
        }
      }
    },
    set_dst_enabled: {
      dataType: "boolean",
      category: "settings",
      fieldConfig: {
        disabled: ({ model }) => !getValue(model.config, model, "edit_rtc_enabled")
      }
    }
  },
  fields: {
    edit_rtc_enabled: {
      dataType: "boolean",
      managedState: true,
      defaultValue: false
    }
  }
});

export default config;
