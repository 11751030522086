import { mergeAssetConfig } from "@/config/asset";
import baseEnergySensor from "./base-energy-sensor";

const config = mergeAssetConfig(baseEnergySensor, {
  properties: {
    maximum_milliampere: {
      format: "decimal2dd",
      unit: "milliamps",
      aggregation: "MAXIMUM"
    },
    minimum_milliampere: {
      format: "decimal2dd",
      unit: "milliamps",
      aggregation: "MAXIMUM"
    }
  }
});

export default config;
