import Vue from "vue";
import VueRouter from "vue-router";
import PortfolioView from "../views/PortfolioView.vue";
import PortfolioMapTab from "../components/PortfolioMapTab.vue";
import PortfolioConfigTab from "../components/PortfolioConfigTab.vue";
import PortfolioUsersTab from "../components/PortfolioUsersTab.vue";
import PortfolioAlertsTab from "../components/PortfolioAlertsTab.vue";
import PortfolioJournalTab from "../components/PortfolioJournalTab.vue";
import BuildingView from "../views/BuildingView.vue";
import BuildingFloorplansTab from "../components/BuildingFloorplansTab.vue";
import BuildingAssetsTab from "../components/BuildingAssetsTab.vue";
import BuildingControlsTab from "../components/BuildingControlsTab.vue";
import BuildingDocumentsTab from "../components/BuildingDocumentsTab.vue";
import BuildingConfigTab from "../components/BuildingConfigTab.vue";
import BuildingIaqReport from "../reports/BuildingIaqReport.vue";
import BuildingEnergyReport from "../reports/BuildingEnergyReport.vue";
import BuildingWirelessDeviceReport from "../reports/BuildingWirelessDeviceReport.vue";
import BuildingReportsTab from "../components/BuildingReportsTab.vue";
import BuildingAlertsTab from "../components/BuildingAlertsTab.vue";
import BuildingJournalTab from "../components/BuildingJournalTab.vue";
import AssetView from "../views/AssetView.vue";
import AssetComponent from "@/components/AssetComponent.vue";
import DocumentView from "../components/DocumentView.vue";
import CallbackView from "../views/CallbackView.vue";
import { authenticationGuard } from "./authentication-guard";
import { confirmedUserRedirectGuard } from "./confirmed-user-redirect-guard";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    component: PortfolioView,
    children: [
      {
        path: "",
        name: "portfolio",
        component: PortfolioMapTab,
        meta: {
          fullWidth: true,
          fullHeightMain: true,
          noPadding: true,
          hideFooter: true
        }
      },
      {
        path: "config",
        name: "portfolioConfig",
        component: PortfolioConfigTab
      },
      {
        path: "users",
        name: "users",
        component: PortfolioUsersTab
      },
      {
        path: "journal",
        name: "journal",
        component: PortfolioJournalTab
      },
      {
        path: "alerts",
        name: "alerts",
        component: PortfolioAlertsTab
      }
    ]
  },
  {
    path: "/buildings/:buildingUuid",
    component: BuildingView,
    children: [
      {
        path: "",
        name: "building",
        component: BuildingFloorplansTab,
        meta: {
          fullWidth: true,
          fullHeightMain: true,
          noPadding: true,
          hideFooter: true
        }
      },
      {
        path: "assets",
        name: "buildingAssets",
        component: BuildingAssetsTab
      },
      {
        path: "controls",
        name: "buildingControls",
        component: BuildingControlsTab
      },
      {
        path: "documents",
        name: "buildingDocuments",
        component: BuildingDocumentsTab
      },
      {
        path: "config",
        name: "buildingConfig",
        component: BuildingConfigTab
      },
      {
        path: "reports",
        name: "buildingReports",
        component: BuildingReportsTab
      },
      {
        path: "journal",
        name: "buildingJournal",
        component: BuildingJournalTab
      },
      {
        path: "alerts",
        name: "buildingAlerts",
        component: BuildingAlertsTab
      }
    ]
  },
  {
    path: "/assets/:assetUuid",
    component: AssetView,
    children: [
      {
        path: "documents",
        name: "assetDocuments",
        component: AssetComponent,
        props: { componentName: "AssetDocumentsTab" }
      },
      {
        path: "config",
        name: "assetConfig",
        component: AssetComponent,
        props: { componentName: "AssetConfigTab" }
      },
      {
        path: "diagnostics",
        name: "assetHistory",
        component: AssetComponent,
        props: { componentName: "AssetHistoryTab" }
      },
      {
        path: "journal",
        name: "assetJournal",
        component: AssetComponent,
        props: { componentName: "AssetJournalTab" }
      },
      {
        path: "alerts",
        name: "assetAlerts",
        component: AssetComponent,
        props: { componentName: "AssetAlertsTab" }
      },
      {
        path: ":property?",
        name: "asset",
        component: AssetComponent,
        props: { componentName: "AssetDashboardTab" }
      }
    ]
  },
  {
    path: "/buildings/:buildingUuid/reports/iaq",
    name: "buildingIaqReport",
    component: BuildingIaqReport,
    props: { iaqType: "room" }
  },
  {
    path: "/buildings/:buildingUuid/reports/cold-storage",
    name: "buildingColdStorageReport",
    component: BuildingIaqReport,
    props: { iaqType: "cold_storage" }
  },
  {
    path: "/buildings/:buildingUuid/reports/energy",
    name: "buildingEnergyReport",
    component: BuildingEnergyReport
  },
  {
    path: "/buildings/:buildingUuid/reports/wireless-device",
    name: "buildingWirelessDeviceReport",
    component: BuildingWirelessDeviceReport
  },
  {
    path: "/documents/:documentUuid",
    name: "documentView",
    component: DocumentView,
    meta: {
      fullWidth: true,
      fullHeightMain: true,
      noPadding: true
    }
  },
  {
    path: "/callback",
    name: "callback",
    component: CallbackView,
    meta: { authNotRequired: true }
  },
  {
    path: "/unconfirmed",
    name: "unconfirmed",
    component: () => import("@/views/UnconfirmedUser.vue"),
    meta: { confirmationNotRequired: true, authNotRequired: true },
    beforeEnter: confirmedUserRedirectGuard
  },
  {
    path: "/no-accounts",
    name: "noAccounts",
    component: () => import("@/views/NoAccounts.vue")
  },
  {
    path: "/error-404",
    name: "error-404",
    component: () => import("@/views/PageNotFound.vue"),
    meta: { authNotRequired: true }
  },
  {
    path: "*",
    redirect: "error-404"
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

router.beforeEach(authenticationGuard);

export default router;
