
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import router from "@/router";
import gql from "graphql-tag";
import { DateTime } from "luxon";
import { orderBy } from "lodash";
import { TranslateResult } from "vue-i18n";
import NavbarLayout from "@/layouts/NavbarLayout.vue";
import DateRangeParams from "@/components/DateRangeParams.vue";
import AppSpinner from "@/components/AppSpinner.vue";
import LabeledHeatmap from "@/components/visualization/LabeledHeatmap.vue";
import { Asset, AssetPropertyHealth, DateRangeFields, AssetConfig, KnownAsset } from "@/types";
import { findKnownAssets, getKnownAsset } from "@/config/asset";

@Component({
  components: {
    NavbarLayout,
    DateRangeParams,
    AppSpinner,
    LabeledHeatmap
  },
  apollo: {
    building: {
      query: gql`
        query Building($buildingUuid: ID!) {
          building(buildingUuid: $buildingUuid) {
            name
            timeZone
          }
        }
      `,
      variables() {
        return {
          buildingUuid: router.currentRoute.params.buildingUuid
        };
      }
    },
    assetHealth: {
      query: gql`
        query AssetHealth($buildingUuid: ID!, $startDate: DateTime!, $endDate: DateTime!) {
          assetHealth: building(buildingUuid: $buildingUuid) {
            assets(knownAssets: true) {
              assetUuid
              knownAssetUuid
              name
              floor {
                position
                name
              }
              assetHealth(startDate: $startDate, endDate: $endDate) {
                normalTimePct
                propertyName
              }
            }
          }
        }
      `,
      variables() {
        let { startDate, endDate } = this.reportParams;
        if (startDate > endDate) [startDate, endDate] = [endDate, startDate];

        return {
          buildingUuid: router.currentRoute.params.buildingUuid,
          startDate: startDate.setZone("utc"),
          endDate: endDate.endOf("day").setZone("utc"),
          timestamp: DateTime.local() // Ignored, but ensures that variables are never the same
        };
      },
      skip() {
        return !this.paramsPresent;
      },
      fetchPolicy: "no-cache",
      notifyOnNetworkStatusChange: true
    }
  }
})
export default class BuildingIaqReport extends Vue {
  @Prop({ type: String, required: true })
  readonly iaqType: string;

  building: Record<string, any> | null = null;
  assetHealth: Record<string, any> | null = null;
  reportParams: DateRangeFields = {};

  @Watch("building")
  initializeDates(): void {
    if (!this.building) return;

    this.$store.commit("setContextualTimeZone", this.building.timeZone ?? null);
    const endDate = DateTime.now().setZone(this.timeZone).startOf("day");
    const startDate = endDate.minus({ days: 7 });

    this.reportParams = { startDate, endDate };
  }

  get paramsPresent(): boolean {
    return this.reportParams.startDate !== undefined;
  }

  get assetNames(): string[] {
    return this.sortedAssets.map(asset => asset.name);
  }

  get propertyNames(): string[] {
    if (this.iaqType === "room") {
      let names = ["temp_c", "rh_pct", "co2_ppm", "tvoc_ppb"];
      if (this.hasPmProperties) names = [...names, "pm2_5", "pm10"];
      return names;
    }
    return ["temp_c", "rh_pct"];
  }

  get propertyLabels(): TranslateResult[] {
    const namespace = this.assetConfig.i18nNamespace;
    return this.propertyNames.map(name => this.$t(`properties.${namespace}.${name}.label`));
  }

  get dataPoints(): number[][] {
    const pts: number[][] = [];
    this.sortedAssets.forEach((asset, i) => {
      // y-axis
      this.propertyNames.forEach((propertyName, j) => {
        // x-axis
        const property = this.assetPropertyHealth(asset, propertyName);
        if (property) {
          const value = Math.round(100 - property.normalTimePct * 100);
          pts.push([j, i, value]);
        }
      });
    });
    return pts;
  }

  get sortedAssets(): Asset[] {
    if (this.assetHealth === null) return [];

    const assets = this.assetHealth.assets.filter((asset: Asset) => {
      const isIncluded = this.isIncludedType(asset.knownAssetUuid);
      const hasHealthData = asset.assetHealth && asset.assetHealth.length > 0;
      return isIncluded && hasHealthData;
    });
    // By default orderBy puts undefined values first when desc. We assign them "" so they will be last
    return orderBy(assets, [asset => asset.floor?.position || "", "name"], ["desc", "asc"]);
  }

  isIncludedType(knownAssetUuid: string | undefined): boolean {
    return this.knownAssets.some(a => a.knownAssetUuid === knownAssetUuid);
  }

  assetPropertyHealth(asset: Asset, propertyName: string): AssetPropertyHealth | undefined {
    const properties = asset.assetHealth ?? [];
    return properties.find(p => this.rewritePropertyName(p.propertyName) === propertyName);
  }

  rewritePropertyName(propertyName: string): string {
    if (propertyName === "tvoc_level") return "tvoc_ppb";
    return propertyName;
  }

  get assetConfig(): AssetConfig {
    return this.knownAssets[0].config.default;
  }

  get knownAssets(): KnownAsset[] {
    const assetFields =
      this.iaqType === "room"
        ? { type: "Sensor - IAQ", subtype: "Indoor" }
        : { type: "Sensor - IAQ", subtype: "Cold Storage" };
    return findKnownAssets(assetFields);
  }

  get reportTitle(): string {
    const title = this.$t(`building_iaq_report.${this.iaqType}.title`).toString();

    if (this.building === null) return title;
    return `${title} &mdash; ${this.building.name}`;
  }

  get dataLoading(): boolean {
    return this.assetHealth === null || this.$apollo.queries.assetHealth.loading;
  }

  get chartDataAvailable(): boolean {
    return this.sortedAssets.length > 0;
  }

  assetUuid(pos: number): string {
    return this.sortedAssets[pos].assetUuid;
  }

  floorName(pos: number): string {
    return this.sortedAssets[pos].floor?.name ?? "";
  }

  tooltip(property: string, asset: string, value: string): string {
    return this.$t("building_iaq_report.tooltip", { property: property, value, asset }).toString();
  }

  get hasPmProperties(): boolean {
    const iaqPmKnownAsset = getKnownAsset({ name: "UBX-IAQ101" });
    return this.sortedAssets.some(a => a.knownAssetUuid === iaqPmKnownAsset?.knownAssetUuid);
  }

  get timeZone(): string {
    return this.$store.getters.timeZone;
  }

  // eslint-disable-next-line
  beforeRouteLeave(to: any, from: any, next: () => void): void {
    this.$store.commit("setContextualTimeZone", null);
    next();
  }
}
