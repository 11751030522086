import gql from "graphql-tag";
import { apolloClient } from "@/plugins/vue-apollo";

export default function (
  documentUuid: string,
  annotationUuid: string,
  text: string,
  parentUuid: string | null,
  rootUuid: string | null
): Promise<any> {
  parentUuid = parentUuid == "" ? null : parentUuid;
  rootUuid = rootUuid == "" ? null : rootUuid;
  return apolloClient
    .mutate({
      mutation: gql`
        mutation ($documentUuid: ID!, $annotationUuid: ID!, $text: String!, $parentUuid: ID, $rootUuid: ID) {
          createAnnotation(
            documentUuid: $documentUuid
            annotationUuid: $annotationUuid
            text: $text
            parentUuid: $parentUuid
            rootUuid: $rootUuid
          ) {
            message
            stamp
            statusCode
          }
        }
      `,
      variables: {
        documentUuid: documentUuid,
        annotationUuid: annotationUuid,
        text: text,
        parentUuid: parentUuid,
        rootUuid: rootUuid
      }
    })
    .then(response => {
      return response.data.createAnnotation;
    });
}
